<template>
  <div>
		<PageBanner
			:title="pageTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
		/>
		<div class="contact-container centering">
			<div class="map-container">
				<arch-map/>
			</div>
			<div class="contact-main">
				<div class="contact-info">
					<ul class="list-unstyled info">
						<li class="img-container">
							<img class="contact-logo" src="@/assets/images/logo_ls.png" alt="">
						</li>
					</ul>
				</div>
				<div class="form-container">
					<div class="form-credentials" id="pjWrapperContactForm_1">
						<div class="container-fluid pjCF-container">
							<div id="pjCF_container_1" class="panel-body">
								<p>Πως μπορούμε να βοηθήσουμε ;</p>
								<!-- <form
									action="https://laskaridisfoundationarchives.org/scripts/form/index.php?controller=pjFront&action=pjActionSubmit"
									name="pjCF_form_1" id="pjCF_form_1" class="pjCF-form form-horizontal" data-toggle="validator" role="form" method="post" enctype="multipart/form-data">
									<input type="hidden" name="id" value="1" />

									<div class="">
											<div class="form-credentials">
												<b-input v-model="userName" type="text" id="pjCF_field_1" name="pjCF_field_1" value="" class="" placeholder="Όνομα">
												</b-input>
												<b-input v-model="userMail" type="text" id="pjCF_field_2" name="pjCF_field_2" value="" class="" placeholder="Email">
												</b-input>
											</div>
									</div>
									<div class="form-group">
										<div class="msg-container">
											<b-form-textarea v-model="userMsg" name="pjCF_field_3" rows="8" placeholder="Το μήνυμά σας..."></b-form-textarea>
											<div class="help-block with-errors">
												<ul class="list-unstyled"></ul>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="form-credentials">
										<div id="pjCaptchaWrapper_1"></div>
											<div class="help-block with-errors">
												<ul class="list-unstyled"></ul>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div
											class="btn-container centering-sm"
										>
										<b-button @click.prevent="sendMail()" class="send-btn" type="submit" variant="primary">Αποστολή</b-button>
										</div>
									</div>
								</form> -->
							<form action="https://laskaridisfoundationarchives.org/scripts/form/index.php?controller=pjFront&action=pjActionSubmit" name="pjCF_form_1" id="pjCF_form_1" class="pjCF-form form-horizontal" data-toggle="validator" role="form" method="post" enctype="multipart/form-data">
								<input type="hidden" name="id" value="1" />

								<div class="form-group form-credentials">
									<div class="">
									<b-input type="text" id="pjCF_field_1" name="pjCF_field_1" value=""  class="form-control pjCF-form-field pjCF-checked-field" placeholder="Όνομα" /> <div class="help-block with-errors"><ul class="list-unstyled"></ul></div>
									</div>
									<span class="spacer"></span>
									<div class="">
									<b-input type="text" id="pjCF_field_2" name="pjCF_field_2" value=""  class="form-control pjCF-form-field pjCF-checked-field" placeholder="Email" /> <div class="help-block with-errors"><ul class="list-unstyled"></ul></div>
									</div>

								</div>
								<div class="form-group">
									<div class="msg-container">
									<textarea name="pjCF_field_3" style="width:100% !important;height:200px !important;" placeholder="Το μήνυμά σας..." class="form-control pjCF-form-field pjCF-checked-field"></textarea> <div class="help-block with-errors"><ul class="list-unstyled"></ul></div>
									</div>
								</div>
								<div class="form-group">
									<label class="control-label">Captcha</label>
									<div class="captcha-container">
										<div id="pjCaptchaWrapper_1"></div> <div class="help-block with-errors"><ul class="list-unstyled"></ul></div>
									</div>
								</div>
								<div class="form-group">
									<div class="btn-container centering-sm">
									<b-button class="send-btn pjCF-button" type="submit" variant="primary">Αποστολή</b-button>
								</div>
								</div>
							</form>
							<div class="form-group success-send">
								<div id="pjCF_message_container_1" class="alert" role="alert"></div>
							</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner.vue"
import Map from "@/components/ui/Map.vue"
export default {
	name: "contact",
	components: {
		PageBanner,
		'arch-map': Map
	},
	data() {
		return {
			pageTitle: "Επικοινωνία",
			pageDsrcptn: "",
			pageImg: "photo_contact.jpg",
			mailUrl: "https://laskaridisfoundationarchives.org/scripts/form/index.php?controller=pjFront&action=pjActionSubmit",
			userMail: "",
			userName: "",
			userMsg: ""
		}
	},
	computed: {
		isMobile() {
			return (window.innerWidth < 430)
		}
	},
	methods: {
		sendMail() {
			// console.log(this.userMail, this.userName, this.userMsg)
			this.$http({
				url: this.mailUrl,
				method: 'POST',
				data: {
					name: this.userName,
					email: this.userMail,
					message: this.userMsg
				}
			}).then((res) => {
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.contact-container {
	width: 100vw;
	padding: 5% 10%;
	flex-direction: row;
	justify-content: space-between;
}

.contact-main {
	display: flex;
	width: 40%;
	justify-content: center;
	flex-direction: column;

	.contact-info {
		width: 100%;
		padding: 0 15px;
	}

	.contact-logo {
		width: 13vw;
		margin-bottom: 6%;
	}

	.form-container {
		width: 100%;

		p {
			text-align: left;
			font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));
			font-weight: bold;
		}
	}
}

.contact-info {

	.info {
		text-align:left;
	}
}

.form-credentials {
	margin-bottom: 4%;
	width: 100%;
	display: flex;
	justify-content: space-between;

	>div {
		flex: 1 1 auto;
	}

	.spacer {
		width: 10px;
	}

	form {
		width: 100%;
		justify-content: space-between;

		input {
			width: 100%;
			background-color: #F5F5F5
		}
	}
}

.msg-container {
	textarea {
		background-color: #F5F5F5;
	}
}

.send-btn {
	min-width: 80px;
	float: right;
	margin-top: 2%;
	margin-bottom: 2%;
	background-color: $tur-blue;
	border: none;
	-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
	-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
	box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
}

.send-btn:active,
.send-btn:hover,
.send-btn:focus {
	background-color: $tur-blue!important;
	border:0;
	-webkit-box-shadow: 10px 10px 50px 0px rgba(246,246,237,1)!important;
	-moz-box-shadow: 10px 10px 50px 0px rgba(246,246,237,1)!important;
	box-shadow: 10px 10px 50px 0px rgba(246,246,237,1)!important;
}

.success-send {
	margin-top: 2%;
}

.form-group {
	text-align: left;

	label {
		width: 100%;
	}
}

.map-container {
	width: 50%;
	height: 100%;
	margin-top: -3%;
}

@media(max-width: 1100px) {
	.contact-main {
		flex-direction: column;
		align-items: center;

		.contact-info {
			width: 90%;
			margin-bottom: 5%;

			.contact-logo {
				width: 18vw;
				min-width: 200px;
			}

			.info {
				text-align: left;
			}
		}

		.form-container {
			width: 90%;
		}

	}
}

@media(max-width: 960px) {
	.contact-container {
		flex-direction: column;

		.map-container {
			width: 100%;
			padding-top: 20px;
		}

		.contact-main {
			width: 100%;
			justify-content: center;

			li {
				display: flex;
				justify-content: flex-start;
				padding: 0 15px;
			}

			.img-container {
				justify-content: center;
				padding: 5%;
			}

			.contact-logo {
				margin-bottom: 0;
				margin-top: 6%;
			}
		}
	}
}

@media(max-width: 720px) {
	.form-credentials {
		flex-direction: column;
	}

	.form-credentials form input {
			width: 100%;
			margin: 2% 0;
	}
}

@media(max-width: 420px) {
	.form-credentials form input {
			width: 100% !important;
			margin-bottom: 15px;
	}

	.send-btn {
		// margin-right: auto;
	}

}
</style>
