<template>
	<span
		class="single-tag"
		:class="{the_tag: tag.gr === theTag }"
		@click="searchTag(tag.gr)"
		:id="setTagID(tag.id)"
	>{{ tag.gr }}
		<b-tooltip v-if="tag.fr && !formatIsGrid" ref="popover" :target="setTagID(tag.id)" triggers="hover" custom-class="tag-tooltip">
			<span class="french" @click="searchTag(tag.gr)">{{ tag.fr }}</span>
    </b-tooltip>
	</span>
</template>

<script>
export default {
	name: "SingleTag",
	props: {
		tag: {
			type: Object,
			required: true
		},
		row: {
			type: Number,
			required: false
		}
	},
	computed: {
		theTag() {
			return this.$store.getters['getQueryTag']
		},
		formatIsGrid() {
			return this.$store.getters['getFormat'] === 'grid'
		}
	},
	methods: {
		searchTag(tag) {
			this.$store.commit('updateQueryTitle', '')
			this.$store.commit('updateQuerySubject', '')
			this.$store.dispatch('searchByTag', tag)
		},
		setTagID(id) {
			return this.row ? `${id}_${this.row}` : id
		}
	}

}

</script>

<style lang="scss" scoped>
	.single-tag {
		width: auto;
		margin: auto 4px;
		cursor: pointer;
	}

	.single-tag:hover {
		text-decoration: underline;
		text-shadow: 0px 1px #c7c7c7;
	}

	.french {
		cursor: pointer;
	}
</style>
