<template>
	<div>
		<toggle-button
			:sync="true"
			v-model="showImg"
			@change="switched()"
			:width="130"
			:height="27"
			:font-size="17"
			:color="{checked: '#95b136', unchecked: '#DDDDDD'}"
			:labels="{checked: 'Εικόνα', unchecked: 'Μεταγραφή'}"
		/>
	</div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: "SwitchBtn",
	components: {
		ToggleButton
	},
	data: () => {
		return {
			showImg: true
		}
	},
	methods: {
		switched() {
			this.$emit('change', this.showImg)
		}
	}
}
</script>
