<template>
	<div>
		<Loader
			v-if="isLoading"
		/>
		<generic-page
			ref="gpage"
			:main-banner="mainImg"
			:p-content="pContent"
			:p-title="pTitle"
		></generic-page>
	</div>
</template>

<script>
export default {
	name: "Research",
	data() {
		return {
			mainImg: "slider_archive.jpg",
			pTitle: "",
			pContent: "",
			frTilte: "",
			frContent: "",
			isLoading: true
		}
	},
	computed: {
		url() {
			return `${this.$store.state.rootUrl}site_pages/40`
		}
	},
	created() {
		this.getPageContent()
		this.getFrench(this.$route.name)
	},
	methods: {
		getPageContent() {
			this.$http
				.get(this.url)
				.then((response) => {
					this.isLoading = false
					this.pContent = response.data[`o:block`][1][`o:data`].html
					this.pTitle = response.data[`o:title`]
				})
		},
		getFrench(slug) {
			if (slug) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.$refs.gpage.frTitle = res.data.page.fr_title
						this.$refs.gpage.frContent = res.data.page.fr_content
					})
			}
		}
	}
}
</script>
