<template>
	<div class="about">
		<PageBanner
			:title="pageTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
		/>
		<div class="single-archive-container about-container centering">
			<Loader v-show="loading || profileData.length > 0" />
			<div class="about-lead" v-show="profileData">
				<div class="about-lead-photo">
					<img class="inner" :src="profileData.mainImg" alt="">
				</div>
				<div class="about-lead-text">
					<div class="lead-title">
						<h2>{{profileData.title}}
							<div class="dash"></div>
						</h2>
					</div>
					<div class="img-container">
						<a :href="profileData.source" target="_blank">
							<img class="pdf-logo" src="@/assets/images/pdf/download_pdf.png" alt="" width="50">
							<!-- <span class='pdf-title'>{{profileData.sourceTitle}}</span> -->
						</a>
						<a @click="goToFlipbook" target="_blank" class='pdf-link' v-if="profileData.pdfImages.length > 0">
							<img class="pdf-logo" src="@/assets/images/pdf/read_online.png" alt="" width="50">
							<!-- <span class='pdf-title'>{{profileData.sourceTitle}}</span> -->
						</a>
						<span class='pdf-title'>{{profileData.sourceTitle}}</span>
					</div>
					<div class="description-container">
						<h3>Περιεχόμενα</h3>
						<div v-html="profileData.description" class="archive-description"></div>
					</div>
				</div>
			</div>
			<p v-show="!profileData">Δεν βρέθηκαν αντικείμενα</p>
		</div>

			<div class="row">
				<!-- <h4>Επιστροφή στην Αναζήτηση</h4>
				<img src="@/assets/images/go_back.svg" alt=""> -->
				<b-button class="src-btn mb-lg-0" @click="searchAgain()"  variant="primary">
					<img src="@/assets/images/arrow_left.png" alt="">
					<span>ΑΝΑΖΗΤΗΣΗ</span>
				</b-button>
			</div>

	</div>
</template>

<script>
import Vuex from 'vuex'
import PageBanner from "@/components/PageBanner.vue"
// import ModalGallery from "@/components/ModalGallery.vue"

export default {
	name: "Profile",
	components: {
		PageBanner
		// ModalGallery
	},
	data() {
		return {
			pageTitle: "",
			pageDsrcptn: "",
			pageImg: "slider_archive.jpg",
			profileImages: [],
			index: null,
			showModal: false,
			indexArray: [],
			singleItem: {
				id: this.$route.params.id
			}
		}
	},
	computed: {
		...Vuex.mapState(['profileData', 'loading']),
		...Vuex.mapGetters(['loadingStatus', 'getResultIds']),
		profileTags() {
			return this.profileData.tags
		},
		currentRecordIndex() {
			const recs = this.$store.getters.getResultIds
			return recs.indexOf(this.$route.params.id)
		},
		resultsLength() {
			return this.$store.getters.getResultIds.length
		}
	},
	created() {
		this.isLoading = true
		this.indexArray = this.$store.getters.getResultIds
		this.loadCurrentProfile(this.$route.params.id)
	},

	methods: {
		loadCurrentProfile(id) {
			this.$store.dispatch('loadProfile', id)
			// this.loadImages(id)
		},
		loadImages(id) {
			this.$http
				.get(`${this.$store.state.rootUrl}media?item_id=${id}&per_page=500`)
				.then((response) => {
					this.isLoading = false
					this.profileImages = this.getImg(response.data)
					this.sliderIndex = 0
				})
		},
		getImg(res) {
			const imagesArray = []
			for (let i = 0; i < res.length; i++) {
				let single = { img: '', description: '' }
				single.img = res[i][`o:original_url`]
				single.description = res[i][`dcterms:description`] ? res[i][`dcterms:description`][0][`@value`] : ""
				imagesArray[i] = single
			}
			return imagesArray
		},
		goBack() {
			this.$router.go(-1)
		},
		searchTag(tag) {
			this.$store.dispatch('searchByTag', tag)
		},
		getToolTip(t) {
			return `<span style="color:#fff;>${t}</span>`
		},
		triggerModal(i) {
			this.index = i
			this.showModal = true
		},
		loadPrev() {
			let current = this.indexArray.indexOf(this.$route.params.id)
			// console.log(current)
			--current
			if (current < 0) {
				return
			}
			this.$router.push({ name: 'profile', params: { id: this.indexArray[current] } })
			this.loadCurrentProfile(this.$route.params.id)
		},
		loadNext() {
			let current = this.indexArray.indexOf(this.$route.params.id)
			++current
			if (current >= this.indexArray.length) {
				return
			}
			this.$router.push({ name: 'profile', params: { id: this.indexArray[current] } })
			this.loadCurrentProfile(this.$route.params.id)
		},
		searchAgain() {
			let currentSet = this.$store.getters.getCurrentSet
			// console.log(currentSet)
			if (currentSet.contentPage) {
				this.$router.push({
					name: 'archives',
					params: { id: currentSet.id, contentPage: currentSet.contentPage }
				})
			} else {
				this.$router.push({ name: 'search' })
			}
			this.$store.dispatch('searchItems')
		},
		goToFlipbook() {
			this.$store.dispatch('applyItemSet', this.$route.params)
			this.$router.push({ path: "/flipbook-view/" + this.$route.params.id })
			// this.$router.go({ path: "/flipbook-view/" + this.$route.params.id })
			// let routeData = this.$router.resolve({ name: 'flipbook-view', query: {this.$route.params.id} })
			// window.open(routeData.href, '_self')
		}
	},
	mounted() {
		// console.log(this.indexArray)
	}
}
</script>
<style lang="scss" scoped>
.img-container {
	text-align:left;
}
.description-container {
	text-align:left;
	margin-top:5%;
}
.description-container > .inner {
	overflow:auto;
	min-height:200px;
}
.archive-description {
	white-space: pre-wrap;
    overflow-y: auto;
    max-height: 28vw;
    overflow-x: hidden;
    text-align: justify;
}
.archive-description::-webkit-scrollbar {
    -webkit-appearance: none;
}

.archive-description::-webkit-scrollbar:vertical {
    width: 11px;
}

.archive-description::-webkit-scrollbar:horizontal {
    height: 11px;
}

.archive-description::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
.pdf-title{
	margin-left:3%;
}
.pdf-link{
	cursor: pointer;
	margin-left:2%;
}
.single-archive-container {
	height: 100%;
	min-height: fit-content;
	min-height: -moz-fit-content;
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: -moz-fit-content;
	min-height: fit-content;
	overflow: auto;
	min-height: 45vh;
}

.row {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 0 15% 5% 15%;
	margin: 0;

		.src-btn {
		background-color: $tur-blue;
		border: none;
		-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		width: 16%;
		min-width: 170px;
		white-space: nowrap;
		margin-left: 2%;
		text-align: center;

		img,span {
			margin-right: 20px;
		}
	}

	.src-btn:active,
	.src-btn:focus {
		box-shadow: none!important;
		background-color: $tur-blue!important;
		border: $light-green;
	}

	h4 {
		font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
	}

	img {
		width: 20px;
		// margin: -10px 0 0 5px
	}
}
	.about-container {
		padding-top: 5%;
	}
	.about-lead {
		width: 100%;
		display: flex;
		min-height: 20vh;
	}

		.main-logo-container {
		margin: 0% auto 4% auto;
		width: 100%;
		text-align: left;

			img {
				width: 20%;
				min-width: 150px;
			}
		}

	.about-lead-photo {
		width: 50%;
		height: 720px;
		.inner {
			//background-image: url('../assets/images/photo_foundation.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 100%;
		}
	}

	.fr-container {
		width: 100%;
    text-align: right;

		.trigger-modal {
			background-color: #95b136;
			border: none;
			color: #fff;
			border-radius: 5px;
			-webkit-appearance: none;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		}
	}

	.about-lead-text {
		width: 50%;
		padding: 0 5%;
		max-height: 128vh;
		overflow: hidden;
		position: relative;

		.lead-title {
			max-width: 635px;
		}

		h2 {
			font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
			font-weight: 100;
			color: #000;
			text-align: left;
			// margin-top: -2%;
			//background-image: url('../assets/images/logo_short.png');
			background-position: 75% 50%;
			background-size: contain ;
			background-repeat: no-repeat;

			.dash {
				width: 25%;
				margin: 5% 0;
			}
		}

		p {
			font-size: 20px;
			font-weight: 100;
			color: #000;
			text-align: justify;
			width: 80%;
			max-width: 500px;
			margin-bottom: 0;
		}
	}
	.about-main-content {
		padding: 5% 25%;

		p {
			text-align: justify;
			font-size: 1rem;
			line-height: 1.8;
		}
	}

		.l-logo {
			display: none;
		}
	@media(min-width: 996px) {

		.l-logo-cont {
			display: none;
		}
	}
	@media(max-width: 1200px) {

		.about-lead-photo {

			.inner {
				//background-image: url('../assets/images/laskaridi.jpg');
				background-size: cover;
				background-position-x: left;
			}
		}

		.about-main-content {
			padding: 5% 15%;
		}

	}

	@media (max-width: 996px) {
		.about-lead {
			flex-direction: column-reverse;
			flex-wrap: wrap;

			.main-logo-container {
				text-align: center;
			}

			.about-lead-photo {
				width: 90%;
				height: 33vw;
				margin-top: 5%;

				.inner {
				//	background-image: url('../assets/images/photo_foundation.jpg');
				}
			}

			.about-lead-text {
				width: 100%;
				padding: 0 10%;

				.lead-title {
					max-width: 100vw;
				}

				h2 {
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					background: #fff;
					.dash {
						margin: 2% 0;
						width: 15%;
					}
				}

				p {
					width: 100%;
					max-width: 100%;
					font-size: 1rem;
				}
			}
		}

			.l-logo-cont {
				padding: 5% 0;
				width: 100%;
				text-align: center;

				img {
					max-width: 12rem;
				}
			}

		.about-main-content {
			padding: 5% 10%;

			p {
			font-size: 14px;
			}

			.l-logo {
				text-align: center;
				width: 100%;
				img{
					width: 14%;
					min-width: 80px;
				}
			}
		}
	}

	@media(max-width: 670px) {

		.about-lead-text h2 {
			font-size: 40px;
			// margin-top: 3%;
			margin-bottom: 4%;
		}
	}
</style>
