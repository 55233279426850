<template>
	<div>
		<b-modal @hide="onHide()" class="img-modal" scrollable size="xl" v-model="modalShow">
			<div
				class="img-cont"
			>
			<h5
				v-show="!imgVisible"
			> {{ entryTitle }} </h5>
				<div v-show="!imgVisible" class="dash"></div>

			<!-- <img :src="imageToShow.img" :width="getImgWidth" alt=""> -->
			<img-loader
				v-show="imgVisible"
			>
				<img slot="image" :src="imageToShow.img" :width="getImgWidth" alt="">
				<div slot="preloader"> <div class="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
			</img-loader>
			<p
				class="transcript"
				v-show="!imgVisible"
			>{{ imageToShow.description }}</p>
			</div>
				<template v-slot:modal-footer>
					<div class="w-100 controls-container">
						<p class="img-ctrls  float-left">
						<b-button
							variant="primary"
							size="sm"
							class=""
							@click="prevImg()"
						>
						<img
							width="18px"
							:disabled="false"
							src="../assets/images/arrow_left.png" alt=""
						>
						</b-button>
							{{ imagesArray.indexOf(imageToShow) + 1 }} / {{ imagesArray.length }}
						<b-button
							variant="primary"
							size="sm"
							class=""
							@click="nxtImg()"
						>
						<img
							width="18px"
							style="visibility: visible"
							src="../assets/images/arrow_right.png" alt=""
						>
						</b-button>
						</p>
						<div>
							<switch-btn
								ref="switchBtn"
								v-show="imageToShow.description"
								@change="imgStatus($event)"
							/>
						</div>
						<div class="zoom-ctrls">
							<span @click="zoomOut" class="btn">&#10134; </span>
							<span @click="zoomIn" class="btn">&#10133; </span>
						</div>
						<!-- <b-button
							variant="primary"
							size="sm"
							class="float-right"
							@click="closeModal()"
						>
							Close
						</b-button> -->
					</div>
				</template>
		</b-modal>
	</div>
</template>

<script>
import SwitchBtn from "@/components/ui/SwitchBtn.vue"
import VueLoadImage from 'vue-load-image'

export default {
	name: 'ModalGallery',
	components: {
		SwitchBtn,
		"img-loader": VueLoadImage
	},
	props: {
		triggerModal: Boolean,
		imagesArray: Array,
		idx: {
			type: Number,
			required: false,
			default: null
		},
		entryTitle: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			imageToShow: {},
			modalShow: true,
			imgWidth: 30,
			imgVisible: true,
			options: {
				container: '#imgCont',
				easing: 'ease-in',
				cancelable: true,
				x: false,
				y: true
			}
		}
	},
	computed: {
		getImgWidth () {
			return `${this.imgWidth}%`
		}
	},
	created() {
	},
	methods: {
		showImg() {
			this.modalShow = true
			this.imageToShow = this.imagesArray[this.idx]
		},
		imgStatus(e) {
			this.imgVisible = e
		},
		resetImg() {
			this.$refs.switchBtn.showImg = true
			this.imgVisible = true
			// this.imgWidth = 100
			this.imgWidth = 30
		},
		prevImg() {
			this.resetImg()
			let pos = this.imagesArray.indexOf(this.imageToShow)
			if (pos > 0) {
				pos--
			} else {
				pos = this.imagesArray.length - 1
			}
			this.imageToShow = this.imagesArray[pos]
		},
		nxtImg() {
			this.resetImg()
			let pos = this.imagesArray.indexOf(this.imageToShow)
			if (pos < this.imagesArray.length - 1) {
				pos++
			} else {
				pos = 0
			}
			this.imageToShow = this.imagesArray[pos]
		},
		zoomOut() {
			if (this.imgWidth > 30) {
				this.imgWidth = this.imgWidth - 5
			} else {
				this.imgWidth = 30
			}
			/* if (this.imgWidth > 90) {
				this.imgWidth = this.imgWidth - 5
			} else {
				this.imgWidth = 90
			} */
		},
		zoomIn() {
			/* if (this.imgWidth < 150) {
				this.imgWidth = this.imgWidth + 5
			} else {
				this.imgWidth = 150
			} */
			if (this.imgWidth < 150) {
				this.imgWidth = this.imgWidth + 5
			} else {
				this.imgWidth = 150
			}
		},
		closeModal() {
			this.modalShow = true
			this.$emit('close')
		},
		onHide() {
			this.$emit('close')
			this.modalShow = true
		}
	},
	mounted() {
		this.showImg()
	}
}

</script>

<style lang="scss" scoped>

	.img-cont {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
		height: 100vh;
		text-align: center;
	}

	.controls-container {
		width: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.img-ctrls {
			width: 10%;
			min-width: 120px;
			display: flex;
			justify-content: space-evenly;
			font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));
		}

		.zoom-ctrls {

			.btn {
				cursor: pointer;
			}

		}

		.float-left {
			margin: 0 5px;
		}
	}

	// LOADER
	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		margin-top: 15%;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid  $success;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $success transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

		h5 {
		text-align: justify;
		padding: 1% 6%;
	}

	.dash {
		margin: 0 auto;
	}

	.transcript {
		text-align: justify;
		padding: 3% 6%;
	}

	@media(max-width: 760px) {
		.controls-container {
			display: flex;
				flex-direction: column-reverse;

				.img-ctrls  {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: baseline;
				}

				.zoom-ctrls {
					visibility: hidden;
				}

				button {
					margin-top: 5px;
				}
		}
	}

</style>
