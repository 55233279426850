<template>
	<div>
		<b-modal
			modal-class="grid-img"
			:header-class="{'hidden': fullImg }"
			@hide="onHide()" scrollable
			size="lg" centered
			v-model="modalShow"
		>
			<div class="img-modal">
				<div :class="{'full-grid': fullImg}" class="img-cont">
				<!-- <img :src="imageToShow.img" :width="getImgWidth" alt=""> -->
				<img-loader
					v-show="imgVisible"
				>
					<img
						@click="showBig"
						v-show="!fullImg"
						slot="image" :src="imageToShow.img"
						:width="getImgWidth"
						alt=""
					>
					<div slot="preloader"> <div class="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
				</img-loader>
					<div v-if="fullImg" class="inner"
					>
					<img
						v-if="fullImg" slot="image" :src="imageToShow.img" :width="getImgWidth" alt="">
					</div>
				</div>
				<div v-if="!fullImg" class="info-cont">
					<div class="archive-title">
						<h4>{{ cardInfo.title }}</h4>
						<!-- <span>{{ cardInfo.id }}</span> -->
					</div>
					<!-- <div class="archive-property">
						<span class="prop">General Folder Number: </span>
						<span class="prop-value">{{ cardInfo.generalFolderNum }} </span>
					</div> -->
					<div v-if="cardInfo.generalFolderName" class="archive-property">
						<span class="prop">Θέση: </span>
						<span class="prop-value">{{ cardInfo.generalFolderName }} </span>
					</div>
					<div v-if="cardInfo.folderNumber" class="archive-property">
						<span class="prop">Φάκελος: </span>
						<span class="prop-value">{{ cardInfo.folderNumber }}</span>
					</div>
					<div v-if="cardInfo.fileNumber" class="archive-property">
						<span class="prop">Υποφάκελος: </span>
						<span class="prop-value">{{ cardInfo.fileNumber }}</span>
					</div>
					<div v-if="cardInfo.subject" class="archive-property">
						<span class="prop">Θέμα: </span>
						<span class="prop-value">{{ cardInfo.subject }}</span>
					</div>
					<div v-if="cardInfo.creator" class="archive-property">
						<span class="prop">Δημιουργός: </span>
						<span class="prop-value">{{ cardInfo.creator }}</span>
					</div>
					<div v-if="cardInfo.date" class="archive-property">
						<span class="prop">Ημερ/νία: </span>
						<span class="prop-value">{{ cardInfo.date }}</span>
					</div>
					<div v-if="cardInfo.tags[0].gr" class="archive-property">
						<span class="prop"> Ετικέτες: </span>
						<div class="tags-container">
							<single-tag
								v-for="tag in cardInfo.tags"
								:key="tag.id"
								:tag="tag"
							></single-tag>
						</div>

					</div>
				</div>
			</div>
			<template v-slot:modal-footer>
				<div class="w-100 controls-container">
					<!-- <p class="img-ctrls  float-left">
					<b-button
						variant="primary"
						size="sm"
						class=""
						@click="prevImg()"
					>
					<img
						width="18px"
						:disabled="false"
						src="../../assets/images/arrow_left.png" alt=""
					>
					</b-button>
						{{ imagesArray.indexOf(imageToShow) + 1 }} / {{ imagesArray.length }}
					<b-button
						variant="primary"
						size="sm"
						class=""
						@click="nxtImg()"
					>
					<img
						width="18px"
						style="visibility: visible"
						src="../../assets/images/arrow_right.png" alt=""
					>
					</b-button>
					</p> -->
					<div>
						<switch-btn
							ref="switchBtn"
							v-show="imageToShow.description"
							@change="imgStatus($event)"
						/>
						<div class="zoom-ctrls" v-if="fullImg">
							<span @click="zoomOut" class="btn">&#10134; </span>
							<span @click="zoomIn" class="btn">&#10133; </span>
						</div>
					</div>
					<b-button
						v-if="fullImg"
						variant="primary"
						size="sm"
						class="float-right"
						@click="closeBig()"
					>
						Κλείσιμο
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import SwitchBtn from "@/components/ui/SwitchBtn.vue"
import VueLoadImage from 'vue-load-image'
import SingleTag from "@/components/SingleTag.vue"

export default {
	name: 'GridModal',
	components: {
		SwitchBtn,
		SingleTag,
		"img-loader": VueLoadImage
	},
	props: {
		triggerModal: Boolean,
		imagesArray: Array,
		idx: {
			type: Number,
			required: false,
			default: 0
		},
		entryTitle: {
			type: String,
			required: false,
			default: ''
		},
		cardInfo: {
			type: Object
		}
	},
	data() {
		return {
			imageToShow: {},
			modalShow: true,
			imgWidth: 100,
			imgVisible: true,
			options: {
				container: '#imgCont',
				easing: 'ease-in',
				cancelable: true,
				x: false,
				y: true
			},
			fullImg: false
		}
	},
	computed: {
		getImgWidth () {
			return `${this.imgWidth}%`
		},
		modalSize() {
			if (this.fullImg) {
				return 'xl'
			}
			return 'lg'
		}
	},
	created() {
	},
	methods: {
		showImg() {
			this.modalShow = true
			this.imageToShow = this.imagesArray[this.idx]
		},
		imgStatus(e) {
			this.imgVisible = e
		},
		resetImg() {
			this.$refs.switchBtn.showImg = true
			this.imgVisible = true
		},
		prevImg() {
			this.resetImg()
			let pos = this.imagesArray.indexOf(this.imageToShow)
			if (pos > 0) {
				pos--
			} else {
				pos = this.imagesArray.length - 1
			}
			this.imageToShow = this.imagesArray[pos]
		},
		nxtImg() {
			this.resetImg()
			let pos = this.imagesArray.indexOf(this.imageToShow)
			if (pos < this.imagesArray.length - 1) {
				pos++
			} else {
				pos = 0
			}
			this.imageToShow = this.imagesArray[pos]
		},
		zoomOut() {
			if (this.imgWidth > 90) {
				this.imgWidth = this.imgWidth - 5
			} else {
				this.imgWidth = 90
			}
		},
		zoomIn() {
			if (this.imgWidth < 150) {
				this.imgWidth = this.imgWidth + 5
			} else {
				this.imgWidth = 150
			}
		},
		closeModal() {
			this.modalShow = true
			this.$emit('close')
		},
		onHide() {
			this.$emit('close')
			this.modalShow = true
		},
		showBig() {
			this.fullImg = true
		},
		closeBig() {
			this.fullImg = false
			this.imgWidth = 100
		}
	},
	mounted() {
		this.showImg()
	}
}

</script>

<style lang="scss" scoped>

	.img-modal {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-column-gap: 20px;
	}

	.img-cont {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top;
		height: 100%;
		text-align: center;
		grid-column: 1;
		cursor: pointer;

		.inner {
			background-size: contain;
			background-repeat: no-repeat;
			background-position-x: center;
			height: 100%;
			min-height: 500px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}

	.full-grid {
		grid-column: 1/3;
		padding-right: 1rem;
		padding-top: 1rem;
		// height:  calc(530px + (750 - 530) * ((100vw - 300px) / (1600 - 300)));;
	}

	.info-cont {
		grid-column: 2;

		.archive-title h4 {
			font-size: 18px;
			font-weight: 700;
		}

		.archive-property {
			margin: 1% 0
		}

		.prop {
			font-weight: 700;
		}
	}

	.controls-container {
		width: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.img-ctrls {
			width: 10%;
			min-width: 120px;
			display: flex;
			justify-content: space-evenly;
			font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));
		}

		.zoom-ctrls {

			.btn {
				cursor: pointer;
			}

		}

		.float-left {
			margin: 0 5px;
		}
	}

	// LOADER
	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		margin-top: 15%;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid  $success;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $success transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

		h5 {
		text-align: justify;
		padding: 1% 6%;
	}

	.dash {
		margin: 0 auto;
	}

	.transcript {
		text-align: justify;
		padding: 3% 6%;
	}

	@media(max-width: 760px) {
		.img-modal {
			display: flex;
			flex-direction: column;

			.info-cont  {
				margin-top: 3rem;
			}
		}

		.controls-container {
			display: flex;
				flex-direction: column-reverse;

				.img-ctrls  {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: baseline;
				}

				.zoom-ctrls {
					visibility: hidden;
				}

				button {
					margin-top: 5px;
				}
		}
	}

</style>
