<template>
	<div v-if="isAvailable">
		<section class="transcribus-container">
			<div @click="searchTranscripts" class="trans-button centering flash-button">
				TRANSKRIBUS
			</div>
			<p>Δείτε όλες τις Μεταγραφές</p>
		</section>
	</div>
</template>

<script>
export default {
	name: 'Transcribus',
	props: {
		isAvailable: {
			type: Boolean
		}
	},
	methods: {
		searchTranscripts() {
			this.$store.dispatch('clearAll')
			this.$store.dispatch('clearSearchTag')
			this.$store.dispatch('srchTranscripts')
			this.$store.commit('gotoSearchStart')
			this.$store.dispatch('searchItems')
			this.$router.push('/search')
		}
	}
}
</script>

<style lang="scss">

	section {
		width: 100%;
		padding: 1% 10%;
		margin: 0% 0.5%;
		text-align: left;
		display: flex;

		.trans-button {
			cursor: pointer;
			background-color:#4a522d;
			font-weight: 700;
			// background-color: $tur-blue;
			color: #fff;
			width: 180px;
			height: 40px;
			padding-left: 15px;
			border-radius: 5px;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			align-items: flex-start;
			// -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
			clip-path: polygon(0 0, 0 100%, 80% 100%, 95% 50%, 80% 0%);
			font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
		}

		p {
			font-weight: 700;
			font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
			width: 80%;
			margin-top: 0.5rem;
			margin-left: 5px;
		}

	}

	// FLASH
	.flash-button {
    -webkit-animation-name: flash;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: flash;
    -moz-animation-duration: 1.5s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    animation-name: flash;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes flash {
    0% { opacity: 1.0; }
    50% { opacity: 0.2; }
		100% { opacity: 1.0; }
}

//Firefox 1+
@-webkit-keyframes flash {
    0% { opacity: 1.0; }
    50% { opacity: 0.2; }
    100% { opacity: 1.0; }
}

//Safari 3-4
@-moz-keyframes flash {
    0% { opacity: 1.0; }
    50% { opacity: 0.2; }
    100% { opacity: 1.0; }
}

@media(max-width: 992px) {
	section {
		padding: 1% 1%;
	}
}

@media(max-width: 670px) {
	section {
		margin-top: 5%;
		.trans-button {
			padding-left: 10px;
		}
	}
}

@media(max-width: 480px) {
	section {
		.trans-button {
			padding-left: 10px;
		}
	}
}

@media(max-width: 480px ) {
	section {
		margin-bottom: 15%;
	}
}
</style>
