<template>
	<div>
		<PageBanner
			:title="pageTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
			:itemSetId = profileData.itemSetId
		/>
		<div class="single-archive-container">
			<div v-if="!loadingStatus" class="single-archive">
				<div class="controls">
					<div @click="loadPrev" class="go-back">
						<img
							v-if="currentRecordIndex > 0"
							src="@/assets/images/arrow_title_1_left.png"
						alt="">
					</div>
					<div @click="loadNext" class="go-next">
						<img
							v-if="currentRecordIndex < resultsLength - 1"
							@click="loadNext()" src="@/assets/images/arrow_title_1_right.png" alt="">
					</div>
				</div>

				<div v-if="false" class="idx-controls">
					<!-- &#171; &#187; &#8250; -->
					<span @click="loadPrev" class="prev"> &#8249; Προηγούμενο</span>
					<span @click="loadNext" class="next"> Επόμενο &#8250; </span>
				</div>
				<div v-if="itemSetIds.includes(profileData.itemSetId) === true">
					<div  class="archive-photo text-center mb-5" v-if="profileImages && profileImages.length > 0">
						<!-- <img src="@/assets/images/photo_archive.png" alt=""> -->
						<img  v-if="profileImages[0]['img']" :src="profileImages[0]['img']" alt="" width="90%">
						<a @click.prevent="triggerModal(0)" href="">
							<b-button class="src-btn mb-lg-0 p-btn gallery-btn mt-5" variant="primary" :class="{ 'btn-secondary': itemSetIds.includes(profileData.itemSetId) === true }">
									<img src="../assets/images/gallery-white.png" alt="">
								&nbsp; <span>ΦΩΤΟΓΡΑΦΙΕΣ</span>
							</b-button>
						</a>
					</div>
					<div class="archive-title">
						<h3 class="a-title" >{{ profileData.title }}</h3>
						<hr class="a-title-hr">
					</div>
					<div class="main-section-two">
						<div class="section-one text-left">
								<div class="archive-property" v-if="profileData.location">
									<span class="prop">Τοποθεσία: </span>
									<span class="prop-value">{{ profileData.location }} </span>
								</div>
								<div class="archive-property" v-if="profileData.geographicalarea">
									<span class="prop">Γεωγραφική περιοχή: </span>
									<span class="prop-value">{{ profileData.geographicalarea }} </span>
								</div>
								<div class="archive-property" v-if="profileData.latitude">
									<span class="prop">Γεωγραφικές συντεταγμένες: </span>
									<span class="prop-value">{{ profileData.latitude }} {{ profileData.longitude }}</span>
								</div>
								<div class="archive-property" v-if="profileData.date">
									<span class="prop">Χρονολογία φάρου: </span>
									<span class="prop-value">{{ profileData.date }}</span>
								</div>
								<div class="archive-property" v-if="profileData.provenance">
									<span class="prop">Προέλευση: </span>
									<span class="prop-value">{{ profileData.provenance }}</span>
								</div>
								<div class="archive-property" v-if="profileData.collection">
									<span class="prop">Συλλογή: </span>
									<span class="prop-value">{{ profileData.collection }}</span>
								</div>
						</div>
						<div class="section-two">
							<div class="map-container" v-if="profileData.latitude">
								<arch-map :latitude="profileData.latitude" :longitude="profileData.longitude" />
							</div>
						</div>
					</div>

					<div class="archive-property" v-if="profileData.description">
						<span class="prop">Περιγραφή – Σχόλια: </span>
						<p class="prop-value">{{ profileData.description }}</p>
					</div>

					<div class="archive-property" v-if="profileData.wiki">
						<span class="prop">Λήμμα Βικιπαίδειας: </span>
						<p class="prop-value"> <a :href="profileData.wiki" target="_blank">Μετάβαση</a></p>
					</div>

				</div>
				<div v-if="itemSetIds.includes(profileData.itemSetId) === false">
					<div class="archive-title">
						<!-- <span class="prop">Περιγραφή: </span> -->
						<h4>{{ profileData.title }}</h4>
						<!-- <span>{{ profileData.id }}</span> -->
					</div>
					<!-- <div class="archive-property">
					<span class="prop">General Folder Number: </span>
					<span class="prop-value">{{ profileData.generalFolderNum }} </span>
				</div> -->
					<div v-if="!isLaskari">
						<!-- <div class="archive-property"  v-if="profileData.generalFolderName">
							<span class="prop">Θέση: </span>
							<span class="prop-value">{{ profileData.generalFolderName }} </span>
						</div> -->
						<!-- <div class="archive-property">
							<span class="prop">Φάκελος: </span>
							<span class="prop-value">{{ profileData.folderNumber }}</span>
						</div>
						<div class="archive-property">
							<span class="prop">Υποφάκελος: </span>
							<span class="prop-value">{{ profileData.fileNumber }}</span>
						</div> -->
						<!-- <div class="archive-property"  v-if="profileData.subject">
							<span class="prop">Θέμα: </span>
							<span class="prop-value">{{ profileData.subject }}</span>
						</div> -->
					</div>
					<div > <!--  v-if="isLaskari" -->
						<div class="archive-property" v-if="profileData.fileNumber">
							<span class="prop">Αριθμός Εγγράφου: </span>
							<span class="prop-value">{{ profileData.fileNumber }}</span>
						</div>
						<div class="archive-property"  v-if="profileData.date">
							<!-- <span class="prop">Χρονολογία(ες): </span> -->
							<span class="prop">Ημερομηνία Εγγράφου: </span>
							<span class="prop-value">{{ profileData.date }} </span>
						</div>
						<div class="archive-property"  v-if="profileData.dimensions">
							<span class="prop">Διαστάσεις : </span>
							<span class="prop-value">{{ profileData.dimensions }} </span>
						</div>
						<div class="archive-property"  v-if="profileData.provenance">
							<span class="prop">Προέλευση : </span>
							<span class="prop-value">{{ profileData.provenance }} </span>
						</div>
						<div class="archive-property"  v-if="profileData.identifier">
							<span class="prop">Κωδικός Αναγνώρισης: </span>
							<span class="prop-value">{{ profileData.identifier }}</span>
						</div>
						<div class="archive-property" v-if="profileData.language">
							<span class="prop">Γλώσσα Εγγράφου: </span>
							<span class="prop-value">{{ profileData.language }}</span>
						</div>
					</div>
					<div class="archive-property" v-if="profileData.folderNumber">
							<span class="prop">Αριθμός Φακέλου: </span>
							<span class="prop-value">{{ profileData.folderNumber }}</span>
						</div>

						<div class="archive-property"  v-if="profileData.pages">
							<span class="prop">Αριθμός Σελίδων: </span>
							<span class="prop-value">{{ profileData.pages }}</span>
						</div>

						<div class="archive-property"  v-if="profileData.place">
							<span class="prop">Τόπος: </span>
							<span class="prop-value">{{ profileData.place }}</span>
						</div>

						<div class="archive-property"   v-if="profileData.sender">
							<span class="prop">Αποστολέας: </span>
							<span class="prop-value">{{ profileData.sender }}</span>
						</div>

						<div class="archive-property"  v-if="profileData.receiver">
							<span class="prop">Παραλήπτης: </span>
							<span class="prop-value">{{ profileData.receiver }}</span>
						</div>
						<div class="archive-property"  v-if="profileData.type">
							<span class="prop">Είδος Εγγράφου: </span>
							<span class="prop-value">{{ profileData.type }}</span>
						</div>
						<div class="archive-property"  v-if="profileData.source">
							<span class="prop">Μεταγραφή κειμένου: </span>
							<span class="prop-value"><a class="prop-value" :href="profileData.source"  target="_blank">Προβολή</a> </span>
				</div>
				<div class="archive-property tags-container">
					<span class="prop">Θέματα: </span>
					<span
						v-for="(tag, i) in profileTags"
						:key="i"
						class="tags-lg prop-value"
						@click="searchTag(tag.gr)"
						v-b-tooltip.hover.v-success :title="tag.fr"
					>
						{{ tag.gr }}
					</span >
					<p
						v-for="tag in profileTags"
						:key="tag.id"
						class="tags-sm prop-value"
						@click="searchTag(tag)"
					>
						{{ tag.gr }}
					</p>
				</div>
			</div>
			<div class="archive-property" v-show="!itemSetIds.includes(profileData.itemSetId)">
					<img
						class="image"
						v-for="(image, i) in profileImages"
						:src="image.img"
						:key="i"
						@click="triggerModal(i)">
						<modal-gallery
							v-if="showModal"
							:entry-title="profileData.title"
							:images-array="profileImages"
							:idx="index"
							@close="showModal=false"
						></modal-gallery>
				</div>
			</div>
			<b-spinner class="spinner" v-if="loadingStatus" label="Spinning"></b-spinner>
		</div>

			<div class="row">
				<!-- <h4>Επιστροφή στην Αναζήτηση</h4>
				<img src="@/assets/images/go_back.svg" alt=""> -->
				<b-button class="src-btn mb-lg-0" @click="searchAgain()"  variant="primary" :class="{ 'btn-secondary': itemSetIds.includes(profileData.itemSetId) === true }">
					<img src="@/assets/images/arrow_left.png" alt="">
					<span>ΑΝΑΖΗΤΗΣΗ</span>
				</b-button>
			</div>

	</div>
</template>

<script>
import Vuex from 'vuex'
import PageBanner from "@/components/PageBanner.vue"
import ModalGallery from "@/components/ModalGallery.vue"
import Map from "@/components/ui/ArchiveMap.vue"
export default {
	name: "Profile",
	components: {
		PageBanner,
		ModalGallery,
		'arch-map': Map
	},
	data() {
		return {
			pageTitle: "",
			pageDsrcptn: "",
			pageImg: "slider_archive.jpg",
			profileImages: [],
			index: null,
			showModal: false,
			indexArray: [],
			singleItem: {
				id: this.$route.params.id
			},
			isLaskari: (this.$route.query.isL === '1') ? 1 : 0,
			itemSetIds: [42360]
		}
	},
	computed: {
		...Vuex.mapState(['profileData', 'loading']),
		...Vuex.mapGetters(['loadingStatus', 'getResultIds']),
		profileTags() {
			return this.profileData.tags
		},
		currentRecordIndex() {
			const recs = this.$store.getters.getResultIds
			return recs.indexOf(this.$route.params.id)
		},
		resultsLength() {
			return this.$store.getters.getResultIds.length
		}
	},
	created() {
		this.isLoading = true
		this.indexArray = this.$store.getters.getResultIds
		this.loadCurrentProfile(this.$route.params.id)
	},

	methods: {
		loadCurrentProfile(id) {
			this.$store.dispatch('loadProfile', id)
			this.loadImages(id)
		},
		loadImages(id) {
			this.$http
				.get(`${this.$store.state.rootUrl}media?item_id=${id}&per_page=500`)
				.then((response) => {
					this.isLoading = false
					this.profileImages = this.getImg(response.data)
					this.sliderIndex = 0
				})
		},
		getImg(res) {
			const imagesArray = []
			for (let i = 0; i < res.length; i++) {
				let single = { img: '', description: '' }
				single.img = res[i][`o:original_url`]
				single.description = res[i][`dcterms:description`] ? res[i][`dcterms:description`][0][`@value`] : ""
				imagesArray[i] = single
			}
			return imagesArray
		},
		goBack() {
			this.$router.go(-1)
		},
		searchTag(tag) {
			this.$store.dispatch('searchByTag', tag)
		},
		getToolTip(t) {
			return `<span style="color:#fff;>${t}</span>`
		},
		triggerModal(i) {
			this.index = i
			this.showModal = true
		},
		loadPrev() {
			let current = this.indexArray.indexOf(this.$route.params.id)
			// console.log(current)
			--current
			if (current < 0) {
				return
			}
			this.$router.push({ name: 'profile', params: { id: this.indexArray[current] } })
			this.loadCurrentProfile(this.$route.params.id)
		},
		loadNext() {
			let current = this.indexArray.indexOf(this.$route.params.id)
			++current
			if (current >= this.indexArray.length) {
				return
			}
			this.$router.push({ name: 'profile', params: { id: this.indexArray[current] } })
			this.loadCurrentProfile(this.$route.params.id)
		},
		searchAgain() {
			let currentSet = this.$store.getters.getCurrentSet
			if (currentSet.contentPage) {
				this.$router.push({
					name: 'archives',
					params: { id: currentSet.id, contentPage: currentSet.contentPage }
				})
			} else {
				this.$router.push({ name: 'search' })
			}
			this.$store.dispatch('searchItems')
		},
		setLogoMethod() {
			// const isSecondaryCookie = Cookies.get('isSecondary');
			if (this.itemSetIds.includes(this.profileData.itemSetId)) {
				this.$store.dispatch('updateLogoBackground', 'logo-blue')
				this.$store.dispatch('updateLogoFooter', 'footer-logo-blue')
				document.cookie = "isSecondary=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"
			} else {
				this.$store.dispatch('updateLogoBackground', 'logo')
				this.$store.dispatch('updateLogoFooter', 'footer-logo-blue')
				document.cookie = "isSecondary=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
			}
		}
	},
	mounted() {
		// console.log(this.indexArray)
		this.setLogoMethod()
	},
	watch: {
		// '$route': 'setLogoMethod',
		'profileData': {
			handler: 'setLogoMethod',
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>

.spinner {
	height: 4rem;
	width: 4rem;
	border: 0.25rem solid $tur-blue;
	border-right-color: transparent;
}

.single-archive-container {
	height: 100%;
	min-height: fit-content;
	min-height: -moz-fit-content;
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: -moz-fit-content;
	min-height: fit-content;
	overflow: auto;
	min-height: 45vh;
}

.single-archive {
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 70%;
}

.controls {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 2%;
	padding-left: 1%;

	img {
		height: 17px;
		cursor: pointer;
	}

	.go-back, .go-next {
		display: flex;
		margin: 0 3%;
	}

	h4 {
		margin: 0 2%;
		font-size: 16px;
		margin-top: -1px;
		cursor: pointer;
	}
}

.row {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 0 15% 5% 15%;
	margin: 0;

		.src-btn {
		background-color: $tur-blue;
		border: none;
		-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		width: 16%;
		min-width: 170px;
		white-space: nowrap;
		margin-left: 2%;
		text-align: center;

		img,span {
			margin-right: 20px;
		}
	}

	.src-btn:active,
	.src-btn:focus {
		box-shadow: none!important;
		background-color: $tur-blue!important;
		border: $light-green;
	}

	h4 {
		font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
	}

	img {
		width: 20px;
		// margin: -10px 0 0 5px
	}
}

.idx-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #95b136;
	padding-bottom: 2%;
	margin-bottom: 2%;

	span {
		color: #000000;
		font-size: 25px;
	}
}

.prev,
.next {
	cursor: pointer;
}

.prev img,
.next img {
	height: 20px;
	width: 25px
}

.archive-title h4 {
	font-size: 18px;
	font-weight: 700;
}

.archive-property {
	margin: 1% 0
}

.prop {
	font-weight: 700;
}

.tags-container {
	min-height: 60px;
}

.archive-property .tags-lg,
.archive-property .tags-sm  {
	// background-color: #ccdbed;
	color: $footer-blue;
	font-weight: 700;
	margin: 3px;
	padding: 3px 4px 3px 5px;
	min-width: 60px;
	min-height: 30px;
	cursor: pointer;
}

.archive-property .tags-lg:hover,
.archive-property .tags-sm:hover {
	text-decoration: underline;
}

.prop-value .tags-lg:hover {
	background-color: #005587;
	color:#fff
}

.tags-sm {
	display: none;
}

.profile-imgs {
	width: 60vw;
	display: inline-flex;
	justify-content: space-around;
}

.unslick {
	display: flex;
}

.carousel-container {
	margin-top: 5%;
	width: 100%;
}

#ctrl-left, #ctrl-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#ctrl-left img, #ctrl-right img {
	max-height: 20px;
	width: 15px;
}

.img-list .vgs__container{
	display: none;
}

.img-list {
	display: flex;
	flex-wrap: wrap;
}

.img-list > div{
	margin: 1% 2% 0 0;
}

.image {
	width: 80px !important;
	height: 150px;
	background-size: cover;
	cursor: pointer;
	margin: 5px;
	border-radius: 3px;
	// border: 1px solid lightgray;
	object-fit: contain;
	width: auto !important;
}

.vgs__container {
	background-color: transparent;
	height: 90vh;
}

.vgs__gallery__container {
	visibility: hidden;
}

.slick-slider{
	display: flex;
}

.single-slide:hover {
	cursor: pointer;
}

.single-slide:hover .thubs{
	-webkit-box-shadow: 2px 10px 18px 1px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 10px 18px 1px rgba(0,0,0,0.75);
	box-shadow: 2px 10px 18px 1px rgba(0,0,0,0.75);
}

.thubs{
	width: 90px;
}

.hideMe {
	visibility: hidden;
}

.slick-slide {
	width: 120px!important;
}

/* .map-container {
	width: 50%;
	height: 100%;
	margin-top: -3%;
} */

@media(max-width: 960px) {
	/* .map-container {
			width: 100%;
			padding-top: 20px;
	} */
}
@media (max-width: 769px) {
	.vgs__container {
		top: 20vw;
	}
}

@media (max-width: 650px) {
	.vgs__container {
		top: 27vw;
	}

	.archive-title {
		h4 {
			font-size: 16px;
		}
	}

	.controls {
		justify-content: space-between;
		padding-bottom: 10px;
	}
}

.map-width
{
	width: 500px;
}

@media only screen and (max-width: 600px) {
		.main-section-two
		{
			display: contents !important;
		}

		.main-section-two .section-one, .main-section-two .section-two
		{
			width: 100% !important;
		}

		.main-section-two .section-two
		{
			margin: 30px 0px;
		}

		.main-section-two .section-two .map-container .map-width
		{
			width: auto !important;
		}
	}

@media (max-width: 460px) {

	.archive-title {
		h4 {
			font-size: 15px;
		}
	}

	.tags-lg {
		display: none;
	}

	.tags-sm {
		display: block;
		margin: 10px 1%!important;
		width: -moz-fit-content;
		width: fit-content;
	}

	.vgs__container {
		top: 33vw;
	}
}
	.tooltip-inner {
		color: #fff!important;
	}
	.p-btn {
		margin-top: 2%;
	}
	.a-title-hr {
		border-bottom: 2px solid #95b136;
    width: 7%;
    margin-left: 0;
	}
	.main-section-two {
		width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 20vh;
	}
	.section-one, .section-two {
		width: 50%;
	}

	.mt-5{
		margin-top: 5%;
	}
	.mb-5{
		margin-bottom: 5%;
	}
</style>
