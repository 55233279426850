<template>
	<div>
		<l-map style="height: 600px" :zoom="zoom" :center="center" :options="{ scrollWheelZoom: false }">
			<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
			<l-marker :lat-lng="marker" ref="myMarker">
				<l-popup ref="myPopup" max-width="400px">
					<ul class="list-unstyled info">
						<li>
						Πραξιτέλους 169 & Μπουμπουλίνας,<br> 185 35 Πειραιάς
						</li>
						<li>
							Τel: +30 216-9003700
						</li>
						<li>
							Email: info@laskaridisfoundation.org
						</li>
					</ul>
				</l-popup>
			</l-marker>
			<l-control-zoom :scroll="false" position="bottomright"  ></l-control-zoom>
		</l-map>
	</div>
</template>

<script>
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControlZoom } from 'vue2-leaflet'

export default {
	name: 'Map',
	components: {
		LMap, LTileLayer, LMarker, LPopup, LControlZoom
	},
	data() {
		return {
			zoom: 17,
			center: L.latLng(37.940359, 23.647135),
			url: 'https://tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a',
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			marker: L.latLng(37.940359, 23.647135)
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.myMarker.mapObject.openPopup()
		})
	}
}
/*
	OpenCycleMap
	https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Transport
	https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Landscape
	https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Outdoors
	https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Transport Dark
	https://tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Spinal Map
	https://tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Pioneer
	https://tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Mobile Atlas
	https://tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	Neighbourhood
	https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=83d024d2a317452fa76bf64649949c8a
	*/
</script>

<style lang="scss" scoped>
.info {
	padding: 1%;

	li {
		padding: 1%;
	}
}
</style>
