<template>
	<div>
		<Loader v-if="isLoading" />
		<generic-page
			v-if="pageContent"
			:main-banner="mainImg"
			:p-title="pageTitle"
		></generic-page>
		<div class="filters-container">
			<arch-filters
				v-if="!isLoading"
				ref="filters"
				:key="searchResults.length"
				:has-trans="isTranscript"
				@newSearch="newSearch"
			></arch-filters>
			<Transcribus :is-available="isTranscript" />
		</div>
		<div class="table-container">
			<search-table
				:search-results="searchResults"
				:search-page="searchPage"
				:total-pages="searchTotalPages"
				:search-table-fields="fields"
				:total-entries='total'
			></search-table>
		</div>
		<div
			class="filler centering"
			v-if="false"
		><h2>Sorry, no content</h2></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArchFilters from '@/components/archivedata/ArchFilters.vue'
import SearchTable from "@/components/archivedata/SearchTable.vue"
import Transcribus from "@/components/Navigation/Transcribus.vue"

export default {
	name: "SearchResults",
	components: {
		SearchTable,
		ArchFilters,
		Transcribus
	},
	data() {
		return {
			mainImg: "slider_archive.jpg",
			pageTitle: "Αποτελέσματα Αναζήτησης",
			fields: [
				{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
				// { key: 'generalFolderNum', label: 'General Folder Number', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'generalFolderName', label: `Θέση`, sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'folderNumber', label: 'Φάκελος', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'fileNumber', label: 'Υποφάκελος', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'subject', label: 'Θέμα', sortable: false, class: 'align-middle text-center' },
				{ key: 'tags', label: 'Ετικέτες', sortable: false, class: 'align-middle tags' },
				{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions' }
			]
		}
	},
	computed: {
		...mapGetters({
			searchResults: 'getQueryResults',
			searchPage: 'getSearchPage',
			searchTotalPages: 'getTotalPages',
			searchedTitle: 'getQueryTitle',
			searchedSubject: 'getQuerySubject',
			total: 'getTotalEntries',
			isLoading: 'getSearchStatus',
			currentCollection: 'getStoredCollection'
		}),
		pageContent() {
			return `Title: ${this.searchedTitle} <br> Subject: ${this.searchedSubject}`
		},
		isTranscript() {
			return this.currentCollection ? this.currentCollection.hasFr : false
		}
	},
	methods: {
		newSearch() {
			this.$store.dispatch('applyItemSet', 0)
			this.$store.dispatch('searchItems')
		}
	}
}
</script>

<style lang="scss" scoped>

.filters-container {
	width: 100%;
	background-color: #fff;
	padding: 2% 0;
}

.table-container {
	margin: 0% 0.5%;
	min-height: 60vh;
}

.title {
	width: 60%;
}

@media(max-width: 1200px) {
}

@media(max-width: 960px) {
}

@media(max-width: 820px) {
}

@media(max-width: 670px) {
	.filters-container {
		height: 56vw;
	}

}

@media(max-width: 480px) {
		.filters-container {
		height: 50vh;
	}
}

@media(max-width: 380px) {
		.filters-container {
		height: 50vh;
		margin-top: 20%;
	}
}

@media(max-width: 360px) {
}
</style>
