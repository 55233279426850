<template>
	<div @click="$emit('selected')" class="archive-card">
		<div class="img-container">
			<!-- <img src="https://picsum.photos/300/600/?image=25" alt=""> -->
			<div class="inner"
				:style="{ 'background-image': 'url(' +  bgImg + ')' }"
			></div>
		</div>
		<div class="title-container">
			<p v-if="title.length < 65">{{ title }}</p>
			<p  v-else>{{ title.substring(0,65)+ "..."  }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Card',
	props: {
		title: {
			type: String
		},
		bgImg: {
			type: String,
			required: true,
			default() {
				return ""
			}
		}
	},
	data: () => {
		return {
			imgUrl: ''
		}
	}
}

</script>

<style lang="scss" scoped>

.archive-card {
		margin: 1rem;
		background: white;
		box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
		border-radius: 8px;
		overflow: hidden;
		transition: all .2s linear;
		height: 13vw;
		width: 22vw;
		padding: 16px 10px;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;

		.img-container {
			width: 35%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.inner {
				background-size: cover;
				background-repeat: no-repeat;
				height: 100%;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				background-image: url('../../assets/images/simi-smal.jpg');
			}
		}

		.title-container  {
			flex: 1 1 auto;
			max-width: 66%;
			padding: 5px 10px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			p  {
				font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
				text-align: left;
				font-weight: 700;
				line-height: 1.3;
				padding-left: 10px;
				max-height: 2.34rem;
				}
			}
}

.archive-card:hover {
	box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
	transform: translate3D(0, -2px, 0);
}

@media screen and (min-width: 600px) {
	.archive-card {
		flex: 1 1 calc(50% - 2rem);
		max-width: 50%;
	}

}

@media screen and (min-width: 960px) {
	.archive-card {
		flex: 1 1 calc(32% - 2rem);
	}
}

@media (max-width: 1200px) {
	.archive-card {
		flex-direction: column;
		min-height: 20rem;

		.img-container  {
			width: 97%;
			display: unset;
			.inner {
				margin: auto;
				background-position-x: center;
				background-size: contain;
			}
		}

		.title-container  {
			height: unset;
			max-width: 97%;
			p {
				width: 100%;
				text-align: center;
				margin-top: 1rem;
			}
		}
	}
}

@media screen and (max-width: 599px) {
	.archive-card {
		flex: 1 1 100%;
		min-width: 50%;
	}

		.title-container p {
			font-size: 15px;
		}

}

</style>
