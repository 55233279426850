<template>
	<div>
		<PageBanner
			:title="bannerTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
		/>
		<div class="sources-main centering">
			<div class="sources-title">
				<h2>Πηγές
					<div class="dash"></div>
				</h2>
			</div>
			<div class="tabs-container">
				<div class="row">
					<div class="col-md-3 tab-controls">
						<div
							v-for="tab in tabs"
							:key="tab.id"
							@click="tabSelected(tab)"
							:class="{ active : selectedTab.title == tab.title }"
							class="tab-item">
							<h3 class="tab-item__heading">{{ tab.title }}</h3>
						</div>
					</div>
					<div class="col-md-9 sources-list">
						<!-- FRENCH BUTTON -->
						<div v-if="frTitle && frContent" class="fr-container">
							<button class="trigger-modal" style="background-color: #95b136" @click="triggerModal">FR</button>
						</div>
							<!-- FRENCH BUTTON -->
						<div
							class="list-container"
							v-html="tabContent"
						></div>
					</div>
					<p class="link-container">
						<text-modal ref="textModal"
							:modal-title="frTitle"
							:modal-content="frContent"
						></text-modal>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageBanner from '@/components/PageBanner.vue'
import TextModal from "@/components/ui/TextModal.vue"

export default {
	name: "Sources",
	components: {
		PageBanner,
		TextModal
	},
	data() {
		return {
			bannerTitle: "",
			pageDsrcptn: "",
			pageImg: "slider_archive.jpg",
			tabContent: "",
			frTitle: "",
			frContent: "",
			tabs: [
				{
					title: "Ιστορικά Πρόσωπα",
					id: 34
				},
				{
					title: "Βιβλιογραφία",
					id: 35
				},
				{
					title: "Ερευνητές - Συνεργάτες",
					id: 36
				}
			],
			selectedTab: {
				title: "Ιστορικά Πρόσωπα",
				id: 34
			}
		}
	},
	created() {
		this.getTabContent(this.selectedTab.id)
	},
	methods: {
		tabSelected(selection) {
			this.selectedTab.title = selection.title
			this.selectedTab.id = selection.id
			this.getTabContent(selection.id)
			this.getFrench(selection.id)
		},
		selectTab() {
			this.selectedTab.title = this.tab.title
		},
		getTabContent(id) {
			this.$http
				.get(`${this.$store.state.rootUrl}site_pages/${id}`)
				.then((response) => {
					this.tabContent = response.data[`o:block`][1][`o:data`].html
				})
		},
		getFrench(slug) {
			if (slug) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.frTitle = res.data.page.fr_title
						this.frContent = res.data.page.fr_content
					})
			}
		},
		triggerModal() {
			this.$refs.textModal.modalShow = true
		}
	}

}
</script>

<style lang="scss" scoped>
.sources-main {
	background-image: linear-gradient(to right, #ffffff 52%, #e2e2c8 100%);
	padding: 5% 10%;
	justify-content: flex-start;

	.sources-title {
		align-self: flex-start
	}
}

h2 {
	font-size: 54px;
	font-weight: 100;

	.dash {
		margin: 17% 0 4% 0;
		width: 57%;
	}
}

.tabs-container {
	width: 100%;
	margin-top: 4%;

	ul {
		text-align: left;
	}

	li {
		font-weight: 700;
	}

	.dash {
		width: 20%;
	}
}
.sources-list {
	border-left: 1px solid $dark-grey;
}

.fr-container {
	width: 100%;
	text-align: right;

	.trigger-modal {
		background-color: #95b136;
		border: none;
		color: #fff;
		border-radius: 5px;
		-webkit-appearance: none;
		-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
	}
}

.tab-item {
  background: white;
  border: 1px #D5DADF solid;
  border-left: 5px solid #D5DADF;
  box-shadow: 0 2px 3px rgba(213,218,223,0.35);
  padding: 15px 0px;
  border-radius: 3px;
  cursor: pointer;
  transition: all .2s ease;
  margin-bottom: 8px;
}

.tab-item:hover, .tab-item.active {
	box-shadow: 0px 3px 3px 2px rgba(213,218,223,0.35);
  border-left: 5px solid $tur-blue;

	.tab-item__heading {
		background: inherit;
	}
}

.tab-item.active {
	background-color: #e8e8cb;

	.tab-item__heading {
		font-weight: bold;
	}

}

.tab-item__heading {
	font-weight: 100;
  font-size: 16px;
  line-height: 1.3em;
  letter-spacing: 0.02em;
	color: rgba(0, 0, 0, 0.85);
  margin: 0px;
}

.tab-item__subheading {
  font-size: 18px;
  color: #333;
  margin: 0px;
}

.tab-content__header {
  color: #314f8d;
  font-weight: bold;
   margin: 0px 0px 30px;
  font-size: 36px;
  line-height: 1.3em;
  letter-spacing: 0.02em;
}

.tab-content__text {
  margin: 0px 0px 30px;
  font-size: 1.25em;
}

.tab-content__btn {
  display: inline-block;
  margin-bottom: 30px;
  padding: 16px 50px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
  transition: all .3s ease;
  text-align: center;
  line-height: 1;
  border: 2px solid;
  border-radius: 3px;
  background-color: transparent;
  box-shadow: 0 2px 3px rgba(213,218,223,0.35);
  color: #24a926;
  fill: #24a926;
  border-color: #24a926;
}

.tab-content__btn:hover {
  color: #24a926;
  text-decoration: none;
  box-shadow: 0px 3px 3px 2px rgba(213,218,223,0.35);
}

.tab-content__testimonial {
  margin-bottom: 15px;
  font-size: 1em;
  color: rgba(0,0,0,.75);
  font-style: italic;
}

.tab-content__testimonial-author {
  margin-bottom: 5px;
  font-size: 1em;
  color: rgba(0,0,0,.75);
  font-weight: bold;
}

</style>
