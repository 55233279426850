<template>
	<div>
		<generic-page
			ref="gpage"
			:main-banner="mainImg"
			:p-content="biographyParagraphs"
			:p-title="bioTitle"
		></generic-page>
		<!-- <generic-page
			ref="gpage"
			:main-banner="mainImg"
			:has-banner="false"
			class="simple-title"
			:p-title="interview"
		></generic-page> -->
		<p style="padding: 1% 20% 5% 20%;">
			Η συνέντευξη παραχωρήθηκε στο Νίκο Μπακουνάκη. Αναπαράγεται εδώ με τη σύμφωνη γνώμη του. Τον ευχαριστούμε θερμά για την παραχώρηση του σπάνιου αυτού υλικού.
		</p>
		<div class="audio-container">
			<arch-audio
				v-if="isComplete"
			></arch-audio>
		</div>
	</div>
</template>

<script>
import ArchAudio from "@/components/ArchAudio.vue"

export default {
	name: "Biography",
	components: {
		ArchAudio
	},
	data() {
		return {
			url: `${this.$store.state.rootUrl}site_pages/5`,
			mainImg: "slider_archive.jpg",
			biographyParagraphs: "",
			bioTitle: "",
			interview: "Συνέντευξη"
		}
	},
	computed: {
		isComplete() {
			return this.$store.getters['audiosComplete']
		}
	},
	created() {
		this.$store.dispatch('loadInterview')
		this.getAllContent()
		this.getFrench(this.$route.name)
	},
	methods: {
		getAllContent() {
			this.$http
				.get(this.url)
				.then((response) => {
					this.biographyParagraphs =	this.getPars(response.data[`o:block`])
					this.bioTitle = response.data[`o:title`]
				})
		},
		getPars(data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i][`o:layout`] === "html") {
					return data[i][`o:data`].html
				}
			}
		},
		getFrench(slug) {
			if (slug) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.$refs.gpage.frTitle = res.data.page.fr_title
						this.$refs.gpage.frContent = res.data.page.fr_content
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>

	.simple-title {
		margin-top: -5%;
		margin-bottom: 2%;
	}

	.audio-container {
		margin-bottom: 5%;
	}
</style>
