<template>
	<div class="single-archive-container">
		<div class="single-archive">
			<!-- <div @click="loadPrev" class="row controls">
				<img src="@/assets/images/arrow_title_1_left.png" alt="">
				<h4>Επιστροφή</h4>
			</div> -->
			<div class="archive-title">
				<h4 class="prop-value">{{ title }}</h4>
				<!-- <span>{{ entryData.id }}</span> -->
			</div>
			<!-- <div v-if="generalFolderNum.trim()" class="archive-property">
				<span class="prop">General Folder Number: </span>
				<span class="prop-value">{{ generalFolderNum }} </span>
			</div> -->
			<div v-if="generalFolderName" class="archive-property">
				<span class="prop">Θέση: </span>
				<span class="prop-value">{{ generalFolderName }} </span>
			</div>
			<div v-if="folderNumber" class="archive-property">
				<span class="prop">Φάκελος: </span>
				<span class="prop-value">{{ folderNumber }}</span>
			</div>
			<div v-if="fileNumber" class="archive-property">
				<span class="prop">Υποφάκελος: </span>
				<span class="prop-value">{{ fileNumber }}</span>
			</div>
			<div v-if="subject" class="archive-property">
				<span class="prop">Θέμα: </span>
				<span class="prop-value">{{ subject }}</span>
			</div>
			<div v-if="profileTags[0].gr" class="archive-property tags-container">
				<span class="prop">Ετικέτες: </span>
				<single-tag
					class="tags-lg prop-value"
					v-for="tag in profileTags"
					:key="tag.id"
					:tag="tag"
				></single-tag>
			</div>
			<div class="archive-property">
				<img
					class="image"
					v-for="(image, i) in profileImages"
					:src="image.img"
					:key="i"
					@click="triggerModal(i)"
				/>
					<modal-gallery
						v-if="showModal"
						:entry-title="title"
						:images-array="profileImages"
						:idx="index"
						@close="showModal=false"
					></modal-gallery>
			</div>
		</div>
		<b-spinner class="spinner" v-if="false" label="Spinning"></b-spinner>
	</div>
</template>

<script>
import ModalGallery from '@/components/ModalGallery'
import SingleTag from "@/components/SingleTag.vue"

export default {
	name: "SingleEntry",
	components: {
		ModalGallery,
		SingleTag
	},
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		title: {
			type: String
		},
		generalFolderName: {
			type: String
		},
		generalFolderNum: {
			type: String
		},
		folderNumber: {
			type: String
		},
		fileNumber: {
			type: String
		},
		profileTags: {
			type: Array
		},
		subject: {
			type: String
		},
		id: {
			type: String
		}
	},
	data() {
		return {
			profileImages: [],
			index: null,
			showModal: false
		}
	},
	methods: {
		loadPrev() {
			window.history.back()
		},
		searchTag(tag) {
			this.$store.dispatch('searchByTag', tag)
		},
		loadImages() {
			this.$http
				.get(`${this.$store.state.rootUrl}media?item_id=${this.id}&per_page=300`)
				.then((response) => {
					this.profileImages = this.getImg(response.data)
					this.sliderIndex = 0
				})
		},
		getImg(res) {
			const imagesArray = []
			for (let i = 0; i < res.length; i++) {
				let single = { img: '', description: '' }
				single.img = res[i][`o:original_url`]
				single.description = res[i][`dcterms:description`] ? res[i][`dcterms:description`][0][`@value`] : ""
				imagesArray[i] = single
			}
			return imagesArray
		},
		triggerModal(i) {
			this.index = i
			this.showModal = true
		}
	},
	mounted() {
		this.loadImages()
	}

}
</script>

<style lang="scss" scoped>

.spinner {
	height: 4rem;
	width: 4rem;
	border: 0.25rem solid $tur-blue;
	border-right-color: transparent;
}

.single-archive-container {
	height: 100%;
	width: 100%;
	min-height: fit-content;
	min-height: -moz-fit-content;
	padding: 0%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: -moz-fit-content;
	min-height: fit-content;
	overflow: auto;
}

.single-archive {
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 90%;
}

.controls {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin-bottom: 2%;
	padding-left: 1%;

	img {
		height: 17px;
		cursor: pointer;
	}

	h4 {
		margin-left: 1%;
		font-size: 16px;
		margin-top: -1px;
		cursor: pointer;
	}
}

.prev,
.next {
	cursor: pointer;
}

.prev img,
.next img {
	height: 20px;
	width: 25px
}

.archive-title h4 {
	font-size: calc(16px + (25 - 16) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 700;
}

.archive-property {
	margin: 1% 0
}

.prop {
	font-weight: 700;
}

.tags-container {
	min-height: 60px;
}

.archive-property .tags-lg,
.archive-property .tags-sm  {
	// background-color: #ccdbed;
	color: $footer-blue;
	font-weight: 700;
	margin: 3px;
	padding: 3px 4px 3px 5px;
	min-width: 60px;
	min-height: 30px;
	cursor: pointer;
}

.archive-property .tags-lg:hover,
.archive-property .tags-sm:hover {
	text-decoration: underline;
}

.prop-value .tags-lg:hover {
	background-color: #005587;
	color:#fff
}

.tags-sm {
	display: none;
}

.profile-imgs {
	width: 60vw;
	display: inline-flex;
	justify-content: space-around;
}

.unslick {
	display: flex;
}

.carousel-container {
	margin-top: 5%;
	width: 100%;
}

#ctrl-left, #ctrl-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#ctrl-left img, #ctrl-right img {
	max-height: 20px;
	width: 15px;
}

.img-list .vgs__container{
	display: none;
}

.img-list {
	display: flex;
	flex-wrap: wrap;
}

.img-list > div{
	margin: 1% 2% 0 0;
}

.image {
	width: 80px!important;
	height: 100px;
	background-size: cover;
	cursor: pointer;
	margin: 5px;
	border-radius: 3px;
	// border: 1px solid lightgray;
	object-fit: contain;
}

.vgs__container {
	background-color: transparent;
	height: 90vh;
}

.vgs__gallery__container {
	visibility: hidden;
}

.slick-slider{
	display: flex;
}

.single-slide:hover {
	cursor: pointer;
}

.single-slide:hover .thubs{
	-webkit-box-shadow: 2px 10px 18px 1px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 10px 18px 1px rgba(0,0,0,0.75);
	box-shadow: 2px 10px 18px 1px rgba(0,0,0,0.75);
}

.thubs{
	width: 90px;
}

.hideMe {
	visibility: hidden;
}

.slick-slide {
	width: 120px!important;
}

@media (max-width: 769px) {
	.vgs__container {
		top: 20vw;
	}
}

@media (max-width: 650px) {
	.vgs__container {
		top: 27vw;
	}

	.archive-title {
		h4 {
			font-size: 16px;
		}
	}
}

@media (max-width: 460px) {

	.archive-title {
		h4 {
			font-size: 15px;
		}
	}

	.tags-lg {
		display: none;
	}

	.tags-sm {
		display: block;
		margin: 10px 1%!important;
		width: -moz-fit-content;
		width: fit-content;
	}

	.vgs__container {
		top: 33vw;
	}
}

</style>
