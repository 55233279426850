<template>
	<div
		v-if="currentCollection"
		class="archives_page"
	>
		<PageBanner
			v-if="mainTxtImg"
			:description="pageDsrcptn"
			:backgroundImg="currentCollection.slider ? currentCollection.slider : pageImg"
		/>
		<div
			v-if="$route.params.contentPage > 0 && mainTxtImg"
			class="main-content"
		>
			<div class="main-text">
				<h2>{{ currentCollection.title }}
					<div class="dash"></div>
					<div v-if="frTitle && frContent" class="fr-container">
						<button class="trigger-modal" style="background-color: #95b136" @click="triggerModal">FR</button>
					</div>
				</h2>
				<div class="lead-text">
					<p v-html="pContent"></p>
					<!-- <p v-if="subCollections">{{ subCollections.length }}</p> -->
				</div>
			</div>
			<div class="main-photo">
				<div class="inner" :style="{ 'background-image': 'url(' + mainTxtImg + ')' }"></div>
			</div>
		</div>
		<p class="link-container">
			<text-modal ref="textModal"
				:modal-title="frTitle"
				:modal-content="frContent"
			></text-modal>
		</p>
		<generic-page
			v-if="!mainTxtImg"
			:main-banner="pageImg"
			:p-content="pContent"
			:p-title="currentCollection.title"
			:itemSetId= parseInt(this.$route.params.id)
		></generic-page>
		<div class="filters-container centering">
			<arch-filters
				ref="filters"
				@newSearch="newSearch"
				:has-trans="currentCollection.hasFr"
				:key="searchRslts.length"
			></arch-filters>
		</div>
		<Transcribus
			:is-available="currentCollection.hasFr"
		/>
		<div
			class="col-results-container"
			v-if="subCollections.length === 0 && currentCollection.format !== 'grid' "
		>
			<div  class="table-container" >
				<Loader v-show="loading || searchStatus" />
				<arch-table
					v-if="!isSearhing"
					v-show="!loading"
					:arch-records="records"
					:arch-fields="fields"
					:current-api-page="apiPage"
					:total-pages="searchTotalPages"
					:total-entries="total"
					@prevClicked="getPrev"
					@nextClicked="getNext"
				></arch-table>
				<search-table
					v-if="isSearhing"
					v-show="!searchStatus"
					:search-results="searchRslts"
					:search-table-fields="fields"
					:total-pages="searchTotalPages"
					:total-entries="total"
					:search-page="searchPage"
					:current-data="currentCollection"
				></search-table>
			</div>
		</div>
		<div class="col-results-container" v-if="subCollections.length > 0">
			<div class="main-text">
				<h2>Συλλογές
					<div class="dash"></div>
				</h2>
			</div>
			<Loader v-show="loading || searchStatus" />
			<div class="cards-container dir-col">
				<ArchiveCard
					v-for="item in subCollections"
					v-show="!searchStatus"
					:key="item.id"
					:card-title="item.shortTitle"
					:bg-img="item.thumbnail['o:id']"
					:arch-link="item.link"
					@click.native="goToArchive(item.id, item.textID)"
					class="collection-card"
				/>
			</div>
		</div>
		<!--  BY VM  -->
		<div class="col-results-container" v-if="currentCollection.format === 'grid' && subCollections.length === 0 && searchTotalPages > 0">
			<div class="main-text">
				<h2>Συλλογές
					<div class="dash"></div>
				</h2>
			</div>
			<Loader v-show="loading || searchStatus" />
			<div class="cards-container dir-col">
				<ArchiveCardLast
					v-for="item in searchRslts"
					v-show="!searchStatus"
					:key="item.id"
					:card-title="item.title"
					:bg-img="item.thumbnail"
					:arch-link="item.link"
					@click.native="getCardInfo(item)"
					class="collection-card"
				/>
			</div>
		</div>
		<!--  BY VM END -->
		<div class="col-results-container" v-if="false">
			<h6
				v-if="currentCollection.format === 'grid' && subCollections.length === 0 && searchTotalPages > 0"
			>Συνολικά Αποτελέσματα: {{ total }}</h6>

			<div
				ref="gridTop"
				v-if="currentCollection.format === 'grid' && subCollections.length === 0"
				class="cards-container max-w-70"
			>
				<Loader v-show="loading || searchStatus" />
				<Card
					v-for="card in searchRslts"
					v-show="!searchStatus"
					:key="card.id"
					:title="card.title"
					:bg-img="card.thumbnail"
					@selected="getCardInfo(card)"
				/>
				<p v-if="searchRslts.length === 0">Δεν βρέθηκαν αντικείμενα</p>
			</div>
			<b-row class="centering pagination-container"
				v-if="currentCollection.format === 'grid' && subCollections.length === 0"
			>
				<b-col v-if="searchTotalPages > 0" sm="2" md="2" class="my-1 pagination-navs">
					<button
						:class="{disabled: searchPage === 1}"
						@click="prevClicked"
						class="btn btn-primary"
					>
						<img
							width="18px"
							:disabled="false"
							src="../assets/images/arrow_left.png" alt=""
						>
					</button>
					<span>
						{{ searchPage }} / {{ searchTotalPages }}
					</span>
					<button
						@click="nextClicked"
						class="btn btn-primary"
						:class="{disabled: searchRslts.length < 10}"
					>
					<img
							width="18px"
							style="visibility: visible"
							src="../assets/images/arrow_right.png" alt=""
						>
					</button>
				</b-col>
			</b-row>
		</div>
		<grid-modal
			v-if="showModalGallery"
			:entry-title="'rowTitle'"
			:images-array="modalImages"
			:idx="sliderIndex"
			:card-info="selectedCard"
			@close="showModalGallery=false"
		></grid-modal>
	</div>
</template>

<script>
import Vuex, { mapGetters } from 'vuex'
import PageBanner from "@/components/PageBanner.vue"
import ArchFilters from "@/components/archivedata/ArchFilters.vue"
import ArchTable from "@/components/archivedata/ArchTable.vue"
import SearchTable from "@/components/archivedata/SearchTable.vue"
import TextModal from "@/components/ui/TextModal.vue"
import Transcribus from "@/components/Navigation/Transcribus.vue"
import ArchiveCard from '@/components/ArchiveCard.vue'
import ArchiveCardLast from '@/components/ArchiveCardLast.vue'
import Card from '@/components/ui/Card.vue'
import GridModal from '@/components/ui/GridModal.vue'

export default {
	name: "Archives",
	components: {
		ArchiveCard,
		ArchiveCardLast,
		Card,
		PageBanner,
		ArchFilters,
		ArchTable,
		TextModal,
		SearchTable,
		Transcribus,
		GridModal
	},
	data() {
		return {
			pageTitle: "",
			pageDsrcptn: "",
			pageImg: "slider_archive.jpg",
			mainTxtImg: '',
			// rowImages: [],
			archives: this.records,
			sliderIndex: null,
			isSearhing: false,
			pContent: "",
			frTitle: "",
			frContent: "",
			dummy: [1, 2, 3, 4, 5, 6],
			fields: [
				/* { key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
				{ key: 'date', label: 'Ημερομηνία', sortable: false, class: 'align-middle text-center numbers' },
				// { key: 'pdflink', label: 'Μεταγραφή', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'tags', label: 'Ετικέτες', sortable: false, class: 'align-middle text-center tags' } */
				{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
				// { key: 'generalFolderNum', label: 'General Folder Number', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'generalFolderName', label: `Θέση`, sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'folderNumber', label: 'Φάκελος', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'fileNumber', label: 'Υποφάκελος', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'subject', label: 'Θέμα', sortable: false, class: 'align-middle text-center' },
				{ key: 'tags', label: 'Ετικέτες', sortable: false, class: 'align-middle tags' },
				{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions' }

			],
			showModalGallery: false,
			modalImages: [],
			selectedCard: null,
			selectedCardDetail: null
		}
	},
	computed: {
		...Vuex.mapState(['apiPage', 'loading']),
		...mapGetters({
			records: 'getUpdateRecords',
			searchRslts: 'getQueryResults',
			searchTotalPages: 'getTotalPages',
			total: 'getTotalEntries',
			searchPage: 'getSearchPage',
			searchStatus: 'getSearchStatus',
			currentCollection: 'getCurrentCollection',
			subCollections: 'getSubcollections',
			keys: 'getKeys'
		}),
		isBibikou() {
			return this.currentCollection.hasTrans
		},
		isSingleEntry() {
			return this.records.length > 1
		}
	},
	updated() {
		this.checkRecords()
		// console.log(this.$route)
	},
	beforeCreate() {
	},
	created() {
		this.$store.dispatch('getSingleCollection', +this.$route.params.id)
		this.$store.dispatch('setCurrenCollection', +this.$route.params.id)
		this.getTableData()
		this.archives = this.records
		this.loadPageTxt()
		// this.getCurrentCollection()
		this.getMainImg()
		this.getFrench(this.$route.params.contentPage)
	},
	mounted() {
		this.archives = this.records
		// this.setLogoMethod()
	},
	methods: {
		getTableData() {
			// this.$store.dispatch('resetPage')
			this.isSearhing = true
			this.$store.dispatch('applyItemSet', this.$route.params)
			this.$store.dispatch('searchItems')
			// 33215 & 33216
			const validIdsSet1 = [34033, 34034, 34605]
			const validIdsSet2 = [38152]
			const validIdsSet3 = [33232, 38381]
			const validIdsSet4 = [42360]
			const validIdsSet5 = [38592]
			const idURL = parseInt(this.$route.params.id)
			document.cookie = "isSecondary=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
			// if (parseInt(this.$route.params.id) === 34033 || parseInt(this.$route.params.id) === 34034 || parseInt(this.$route.params.id) === 34605) {
			if (validIdsSet1.includes(idURL)) {
				this.fields = [
					{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title special-title' },
					/* { key: 'folderNumber', label: '', sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'fileNumber', label: '', sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'subject', label: '', sortable: false, class: 'align-middle text-center' },
					{ key: 'tags', label: '', sortable: false, class: 'align-middle tags' }, */
					{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions ' }
				]
				// } else if (parseInt(this.$route.params.id) === 38152) {
			} else if (validIdsSet2.includes(idURL)) {
				this.fields = [
					{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
					{ key: 'date', label: `Χρονολογία(ες)`, sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'language', label: 'Γλώσσα γραφή τεκμηρίων', sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'identifier', label: 'Κωδικός Αναγνώρισης', sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions' }
				]
			// } else if (parseInt(this.$route.params.id) === 33232 || parseInt(this.$route.params.id) === 38381 || parseInt(this.$route.params.id) === 38381) {
			} else if (validIdsSet3.includes(idURL)) {
				this.fields = [
					{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
					{ key: 'date', label: 'Ημερομηνία', sortable: false, class: 'align-middle text-center numbers' },
					// { key: 'pdflink', label: 'Μεταγραφή', sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'tags', label: 'Ετικέτες', sortable: false, class: 'align-middle text-center tags' }
				]
			} else if (validIdsSet4.includes(idURL)) {
				this.$store.dispatch('updateLogoBackground', 'logo-blue')
				this.$store.dispatch('updateLogoFooter', 'footer-logo-blue')
				this.fields = [
					{ key: "title", label: 'Ονομασία', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
					{ key: 'geographicalarea', label: 'Γεωγραφική περιοχή', sortable: false, class: 'align-middle text-center numbers' },
					{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions ' }
				]
			} else if (validIdsSet5.includes(idURL)) {
				// this.$store.dispatch('updateLogoBackground', 'logo-blue')
				// this.$store.dispatch('updateLogoFooter', 'footer-logo-blue')
				this.fields = [
					{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title special-title' },
					{ key: 'tags', label: 'Θέματα', sortable: false, class: 'align-middle  text-center tags' },
					{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions ' }
				]
			}
			// (Title, Date, language, identifier)
			// (Title, Date, Identifier, Description, creator, language) in greek those are:
			// Τίτλος, Χρονολογία(ες), Κωδικός Αναγνώρισης, Παρουσίαση περιεχομένου, Όνομα παραγωγού, Γλώσσα γραφή τεκμηρίων)
		},
		getPrev() {
			this.$store.dispatch('prevApiPage')
		},
		getNext() {
			this.$store.dispatch('nextApiPage')
		},
		refreshPageNo() {
			// this.$store.dispatch('refreshApiPage')
		},
		loadPageTxt() {
			// Check if text data are complete in omeka
			if (this.$route.params.contentPage > 0) {
				let contentUrl = `${this.$store.state.rootUrl}site_pages/${this.$route.params.contentPage}`
				this.$http
					.get(contentUrl)
					.then((response) => {
						this.pContent = this.getPars(response.data[`o:block`])
						this.pageTitle = response.data['o:title']
					})
			} else {
				this.pContent = ''
				this.pageTitle = ' "foaf:page" is missing'
			}
		},
		getPars(data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i][`o:layout`] === "html") {
					return data[i][`o:data`].html
				}
			}
		},
		newSearch() {
			this.isSearhing = true
			this.$store.dispatch('applyItemSet', this.$route.params)
			this.$store.dispatch('searchItems')
			if (this.subCollections.length > 0) {
				this.$router.push({ path: `/search` })
			}
		},
		checkRecords() {
			return (this.records.length + this.searchRslts.length > 2)
		},
		getMainImg () {
			if (this.$route.params.id > 1) {
				let url = `${this.$store.state.rootUrl}item_sets?${this.keys}&id=${this.$route.params.id}`
				this.$http.get(url).then((res) => {
					this.mainTxtImg = res.data['foaf:img'][1]['@id']
				}).catch((error) => {
					// console.log(error)
					this.error = error
					this.mainTxtImg = false
					this.err = error
				})
			}
		},
		triggerModal() {
			this.$refs.textModal.modalShow = true
		},
		getFrench(slug) {
			if (slug > 0) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.frTitle = res.data.page.fr_title
						this.frContent = res.data.page.fr_content
					})
			}
		},
		goToArchive(id, page) {
			this.pContent = ''
			this.$store.dispatch('setCurrenCollection', id)
			this.$router.push({ path: `/archives/${id}/${page}` })
		},
		goToCard(id) {
			// console.log(id)
			this.$store.dispatch('setCurrenCollection', id)
			this.$router.push({ path: `/archives/subcollections/${id}` })
		},
		goToProfile(e, idx) {
			this.$store.dispatch('applyItemSet', this.$route.params)
			this.$router.push({ path: "/profile-detail/" + e.id })
		},
		getCardInfo(card) {
			// console.log(card)
			this.selectedCardDetail = ''
			this.$http.get(`${this.$store.state.rootUrl}items?item_set_id=${this.$route.params.id}&id=${card.id}`).then((response) => {
				this.selectedCardDetail = response.data[`foaf:theme`]
				if (this.selectedCardDetail) {
					if (this.selectedCardDetail[0][`@value`] === 'pdf') {
						this.goToProfile(card, 0)
					} else {
						this.showPopupCard(card)
					}
				} else {
					this.showPopupCard(card)
				}
			})
		},
		showPopupCard(card) {
			this.selectedCard = card
			this.$http.get(`${this.$store.state.rootUrl}media?item_id=${card.id}&per_page=500`)
				.then((response) => {
					this.modalImages = this.getImg(response.data)
					this.sliderIndex = 0
					this.showModalGallery = true
				})
		},
		getImg(res) {
			const imagesArray = []
			for (let i = 0; i < res.length; i++) {
				let single = { img: '', description: '' }
				single.img = res[i][`o:original_url`]
				single.description = res[i][`dcterms:description`] ? res[i][`dcterms:description`][0][`@value`] : ""
				imagesArray[i] = single
			}
			return imagesArray
		},
		prevClicked() {
			if (this.searchPage > 1) {
				this.scrollBack()
				this.$store.dispatch('previousSearchPage')
			}
		},
		nextClicked() {
			if (this.searchRslts.length === 12) {
				this.scrollBack()
				this.$store.dispatch('nxtSearchPage')
			}
		},
		scrollBack() {
			let element = this.$refs.gridTop
			let fromTop = element.offsetTop - 250
			window.scrollTo({ top: fromTop, behavior: 'smooth' })
		}
	},
	watch: {
		'$route.params.id': function (id) {
			// console.log("changed")
			this.$store.dispatch('getSingleCollection', +this.$route.params.id)
			this.$store.dispatch('setCurrenCollection', +this.$route.params.id)
			this.getTableData()
			this.loadPageTxt()
			this.getMainImg()
			this.archives = this.searchRslts
			this.checkRecords()
			this.getFrench(this.$route.params.contentPage)
		}
	}
}
</script>

<style lang="scss" scoped>

.archives_page {
	display: block;
}

.main-content {
	padding: 4% 0 4% 10%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	// height: 1100px;
	width: 100vw;
	justify-content: space-between;

	a {
		color: $footer-blue;
	}
}

.main-text {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: left;
}

.main-text h2 {
	font-size: 50px;
	font-weight: 100;
	color: #000;
	text-align: left;
	margin-left: 0%;
	margin-top: 0;

	.dash {
		margin-top: 2%;
	}
}
.main-text p {
	font-size: 1rem;
	font-weight: 400;
	color: #000;
	text-align: justify;
	margin: 4% 0;
	line-height: 1.5;
}

.main-photo {
	padding-left: 5%;
	// height: 100%
}

.main-photo .inner {
	// background-image: url('../assets/images/photo_bibikou.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	padding: 10%;
	width: 45vw;
}

.filters-container {
	width: 100%;
	background-color: #fff;
	padding: 2% 0;
	// height: 200px;
}

.table-container {
	margin: 0% 0.5%;
	min-height: 60vh;
}

.title {
	width: 60%;
}

.fr-container {
	width: 100%;
	line-height: 24px;
	font-size: 1rem;
	text-align: right;
	margin: 12px 0;
}

.trigger-modal {
	background-color: #95b136;
	border: none;
	color: #fff;
	border-radius: 5px;
	-webkit-appearance: none;
	-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
	-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
	box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
}
.col-results-container {
	h2{
		text-align: center;
	}

	.dash {
		margin: 2%  auto;
	}

	.cards-container  {
		display: flex;
		flex-wrap: wrap;
		width: 95%;
		margin: 1%  auto 5% auto;
		justify-content: center;
	}

	.max-w-70 {
		max-width: 80vw;
	}

}

.pagination-container {
	margin-bottom: 3rem;
}

.pagination-navs {
	display: inherit;
	justify-content: space-around;
	align-items: center;
	min-width: 200px;

	button {
		background-color: $tur-blue;
		padding: 4px 8px;
    border-radius: 50px;
	}

	button.disabled {
		background-color: $light-green;
	}
}

@media(max-width: 1200px) {

	.main-text p {
		line-height: 1.5;
		font-size: 1rem;
	}
}

@media(max-width: 960px) {

	.dash {
		margin-top: 2vw;
	}

	.main-content {
		padding: 4% 10%;
		height: auto;
	}

	.main-photo {
		display: none;
	}

	.dir-col  {
		flex-direction: column!important;

		.collection-card  {
			max-width: 54%;
			max-height: 290px;
			align-self: center;
		}
	}

}

@media(max-width: 820px) {

}

@media(max-width: 670px) {

	.main-content {
		min-height: 660px;
	}

	.main-text p {
		line-height: 1.5;
		font-size: 16px;
	}

}

@media(max-width: 480px) {

		.main-text h2 {
		font-size: 40px;
		margin-top: 4%;
		margin-bottom: 6%;
	}

	.main-content {
		margin-bottom: 7vw
	}
	.dir-col .collection-card {
		max-width: 85%;
	}
}

@media(max-width: 380px) {

	.main-content {
		margin-bottom: 2%;
		// height: 99vh;
	}

	.filters-container {
		justify-content: space-evenly;
		margin: 20% 0;
	}
}

@media(max-width: 360px) {

}
</style>
