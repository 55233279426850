<template>
	<div class="about">
		<PageBanner
			:title="pageTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
		/>
		<div class="about-container centering">

			<div class="about-lead">
				<div class="about-lead-photo">
					<div class="inner"></div>
				</div>
				<div class="about-lead-text">
							<!-- FRENCH BUTTON -->
							<div v-if="frTitle && frContent" class="fr-container">
								<button class="trigger-modal" style="background-color: #95b136" @click="triggerModal">FR</button>
							</div>
							<!-- FRENCH BUTTON -->
					<div class="lead-title">
						<h2>Ποιοι Είμαστε
							<div class="dash"></div>
						</h2>
						<div class="l-logo-cont">
							<img src="@/assets/images/logo_ls.png" alt="">
						</div>
					</div>
					<p v-if="paragraphs.length > 0" v-html="paragraphs[0].html">
					</p>
				</div>
			</div>
			<div class="about-main-content centering">
				<p v-if="paragraphs.length > 0" v-html="paragraphs[1].html">
				</p>
				<p class="l-logo centering">
					<img src="@/assets/images/logo_short.png" alt="">
				</p>
			</div>
			<p class="link-container">
				<text-modal ref="textModal"
					:modal-title="frTitle"
					:modal-content="frContent"
				></text-modal>
			</p>
		</div>
	</div>
</template>

<script>
import PageBanner from "@/components/PageBanner.vue"
import TextModal from "@/components/ui/TextModal.vue"

export default {
	name: "About",
	components: {
		TextModal,
		PageBanner
	},
	data() {
		return {
			pageTitle: "",
			pageDsrcptn: "",
			frTitle: "",
			frContent: "",
			pageImg: "photo_about.jpg",
			paragraphs: [],
			url: `${this.$store.state.rootUrl}site_pages/3`

		}
	},
	created() {
		this.loadContent()
		this.getFrench(this.$route.name)
	},
	methods: {
		loadContent() {
			this.$http
				.get(this.url)
				.then((response) => {
					this.getParagraphs(response.data[`o:block`])
				})
		},
		getParagraphs(resParagraphs) {
			for (let i = 0; i < resParagraphs.length; i++) {
				if (resParagraphs[i][`o:layout`] === "html") {
					this.paragraphs.push(resParagraphs[i][`o:data`])
				}
			}
		},
		getFrench(slug) {
			if (slug) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.frTitle = res.data.page.fr_title
						this.frContent = res.data.page.fr_content
					})
			}
		},
		triggerModal() {
			this.$refs.textModal.modalShow = true
		}
	}
}
</script>

<style lang="scss" scoped>
	.about-container {
		padding-top: 5%;
	}
	.about-lead {
		width: 100%;
		display: flex;
		min-height: 20vh;
	}

		.main-logo-container {
		margin: 0% auto 4% auto;
		width: 100%;
		text-align: left;

			img {
				width: 20%;
				min-width: 150px;
			}
		}

	.about-lead-photo {
		width: 50%;

		.inner {
			background-image: url('../assets/images/photo_foundation.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 100%;
		}
	}

	.fr-container {
		width: 100%;
    text-align: right;

		.trigger-modal {
			background-color: #95b136;
			border: none;
			color: #fff;
			border-radius: 5px;
			-webkit-appearance: none;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		}
	}

	.about-lead-text {
		width: 50%;
		padding: 0 5%;

		.lead-title {
			max-width: 635px;
		}

		h2 {
			font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
			font-weight: 100;
			color: #000;
			text-align: left;
			margin-top: -2%;
			background-image: url('../assets/images/logo_short.png');
			background-position: 75% 50%;
			background-size: contain ;
			background-repeat: no-repeat;

			.dash {
				width: 25%;
				margin: 5% 0;
			}
		}

		p {
			font-size: 20px;
			font-weight: 100;
			color: #000;
			text-align: justify;
			width: 80%;
			max-width: 500px;
			margin-bottom: 0;
		}
	}
	.about-main-content {
		padding: 5% 25%;

		p {
			text-align: justify;
			font-size: 1rem;
			line-height: 1.8;
		}
	}

		.l-logo {
			display: none;
		}
	@media(min-width: 996px) {

		.l-logo-cont {
			display: none;
		}
	}
	@media(max-width: 1200px) {

		.about-lead-photo {

			.inner {
				background-image: url('../assets/images/laskaridi.jpg');
				background-size: cover;
				background-position-x: left;
			}
		}

		.about-main-content {
			padding: 5% 15%;
		}

	}

	@media (max-width: 996px) {
		.about-lead {
			flex-direction: column-reverse;
			flex-wrap: wrap;

			.main-logo-container {
				text-align: center;
			}

			.about-lead-photo {
				width: 100%;
				height: 33vw;
				margin-top: 5%;

				.inner {
					background-image: url('../assets/images/photo_foundation.jpg');
				}
			}

			.about-lead-text {
				width: 100%;
				padding: 0 10%;

				.lead-title {
					max-width: 100vw;
				}

				h2 {
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					background: #fff;
					.dash {
						margin: 2% 0;
						width: 15%;
					}
				}

				p {
					width: 100%;
					max-width: 100%;
					font-size: 1rem;
				}
			}
		}

			.l-logo-cont {
				padding: 5% 0;
				width: 100%;
				text-align: center;

				img {
					max-width: 12rem;
				}
			}

		.about-main-content {
			padding: 5% 10%;

			p {
			font-size: 14px;
			}

			.l-logo {
				text-align: center;
				width: 100%;
				img{
					width: 14%;
					min-width: 80px;
				}
			}
		}
	}

	@media(max-width: 670px) {

		.about-lead-text h2 {
			font-size: 40px;
			margin-top: 3%;
			margin-bottom: 4%;
		}
	}
</style>
