<template>
	<div>
		<generic-page
			:main-banner="mainImg"
			:p-content="pageContent"
			:p-title="pageTitle"
		></generic-page>
		<div ref="tableTop" class="filters-container centering">
			<arch-filters
				@newSearch="newSearch"
			></arch-filters>
		</div>
		<div class="table-container">
			<Loader v-show="loading || searchStatus" />
			<arch-table
				v-if="!isSearhing"
				v-show="!loading"
				:arch-records="singleArchive"
				:arch-fields="fields"
				:current-api-page="collectionPage"
				:total-entries='total'
				:total-pages="searchTotalPages"
				@showgallery="loadGallery"
				@prevClicked="getPrev"
				@nextClicked="getNext"
			></arch-table>
			<search-table
				v-if="isSearhing"
				v-show="!searchStatus"
				:search-results="searchRslts"
				:total-entries='total'
				:total-pages="searchTotalPages"
				:search-table-fields="fields"
				:search-page="searchPage"
			></search-table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArchFilters from "@/components/archivedata/ArchFilters.vue"
import SearchTable from "@/components/archivedata/SearchTable.vue"
import ArchTable from "@/components/archivedata/ArchTable.vue"

export default {
	name: "SubCollection",
	components: {
		ArchTable,
		ArchFilters,
		SearchTable
	},
	data() {
		return {
			pageContent: "",
			pageTitle: "Eleni Antoniadis Bibikou Archive",
			mainImg: "slider_archive.jpg",
			pageDsrcptn: "",
			rowImages: [],
			sliderIndex: null,
			isSearhing: false,
			pageImg: "slider_archive.jpg",
			fields: [
				{ key: "title", label: 'Τίτλος', sortable: false, sortDirection: 'desc', class: 'align-middle title' },
				// { key: 'generalFolderNum', label: 'General Folder Number', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'generalFolderName', label: `Θέση`, sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'folderNumber', label: 'Φάκελος', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'fileNumber', label: 'Υποφάκελος', sortable: false, class: 'align-middle text-center numbers' },
				{ key: 'subject', label: 'Θέμα', sortable: false, class: 'align-middle text-center' },
				{ key: 'tags', label: 'Ετικέτες', sortable: false, class: 'align-middle tags' },
				{ key: 'actions', label: '', sortable: false, class: 'align-middle text-center actions' }
			]
		}
	},
	computed: {
		...mapGetters({
			loading: 'isLoading',
			collectionPage: 'currentPage',
			searchRslts: 'getQueryResults',
			searchTotalPages: 'getTotalPages',
			total: 'getTotalEntries',
			searchPage: 'getSearchPage',
			searchStatus: 'getSearchStatus'
		}),
		singleArchive: {
			get() {
				return this.$store.getters.getNewCollection
			},
			set(value) {
				this.$store.dispatch('searchItems', value)
			}
		}
	},
	created() {
		this.getCollectionData()
		this.getPageText()
	},
	methods: {
		newSearch() {
			// console.log("search triggered")
			this.isSearhing = true
			this.$store.dispatch('applyItemSet', this.$route.params)
			this.$store.dispatch('searchItems')
		},
		getCollectionData() {
			this.$store.dispatch('loadCollection')
		},
		loadGallery(id) {
			this.$http
				.get(`${this.$store.state.rootUrl}media?item_id=${id}`)
				.then((response) => {
					this.rowImages = this.getImg(response.data)
					this.sliderIndex = 0
				})
		},
		getImg(res) {
			const imagesArray = []
			for (let i = 0; i < res.length; i++) {
				imagesArray[i] = res[i][`o:original_url`]
			}
			return imagesArray
		},
		getPrev() {
			this.scrollBack()
			this.$store.dispatch('prevCollectionPage')
		},
		getNext() {
			// console.log("next clicked")
			this.scrollBack()
			this.$store.dispatch('nextCollectionPage')
		},
		scrollBack() {
			let element = this.$refs.tableTop
			let fromTop = element.offsetTop
			window.scrollTo({ top: fromTop, behavior: 'smooth' })
		},
		getPageText() {
			if (this.$route.params.contentPage > 0) {
				this.$http
					.get(`${this.$store.state.rootUrl}site_pages/${this.$route.params.contentPage}`)
					.then((response) => {
						this.pageContent = response.data[`o:block`][1][`o:data`].html
						this.pageTitle = response.data[`o:title`]
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.table-container {
	margin: 2% 0.5%;
	min-height: 60vh;
}

.filters-container {
	width: 100%;
	background-color: #fff;
	padding: 2% 0;
	// height: 200px;
}

@media(max-width: 670px) {

	.filters-container {
		height: 70vw;
	}

}

@media(max-width: 480px) {

	.filters-container {
		height: 50vh;
	}
}

@media(max-width: 380px) {

	.filters-container {
		height: 63vh;
	}
}

</style>
