<template>
	<div class="about">
		<!--
		<PageBanner
			:title="pageTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
		/>
		-->
		<div class="single-archive-container about-container centering">
			<Loader v-show="loading || profileData.length > 0" />
			<template>
				<!-- <flipbook class="flipbook" :pages="pagesList"></flipbook> -->
				<!--
				<flipbook class="flipbook" :pages="['https://picsum.photos/id/237/800/1000','https://picsum.photos/seed/picsum/800/1000','https://picsum.photos/id/247/800/1000', 'https://picsum.photos/id/237/800/1000', 'https://picsum.photos/id/3237/800/1000', 'https://picsum.photos/id/337/800/1000', 'https://picsum.photos/id/837/800/1000', 'https://picsum.photos/id/2937/800/1000']">
				</flipbook>
				-->
				<Flipbook
					class="flipbook"
					:pages="pagesList"
					:pagesHiRes="pagesHiRes"
					:startPage="pageNum"
					v-slot="flipbook"
					v-if="pagesList"
					ref="flipbook"
				>
					<div class="action-bar">
						<left-icon
							class="btn left"
							:class="{ disabled: !flipbook.canFlipLeft }"
							@click="flipbook.flipLeft"
						/>
						<plus-icon
							class="btn plus"
							:class="{ disabled: !flipbook.canZoomIn }"
							@click="flipbook.zoomIn"
							/>
							<span class="page-num"> Σελίδα {{ flipbook.page }} από {{ flipbook.numPages }}
							</span>
							<minus-icon
							class="btn minus"
							:class="{ disabled: !flipbook.canZoomOut }"
							@click="flipbook.zoomOut"
							/>
							<right-icon
							class="btn right"
							:class="{ disabled: !flipbook.canFlipRight }"
							@click="flipbook.flipRight"
							/>
						</div>
					</Flipbook>
				</template>
			<p v-show="!profileData">Δεν βρέθηκαν αντικείμενα</p>
		</div>
		<div class="row">
			<!-- <h4>Επιστροφή στην Αναζήτηση</h4>
			<img src="@/assets/images/go_back.svg" alt=""> -->
			<b-button class="src-btn mb-lg-0" @click="searchAgain()"  variant="primary">
				<img src="@/assets/images/arrow_left.png" alt="">
				<span>ΑΝΑΖΗΤΗΣΗ</span>
			</b-button>
		</div>
	</div>
</template>
<script>
import Vuex from 'vuex'
// import PageBanner from "@/components/PageBanner.vue"
// import Flipbook from 'flipbook-vue'
// import ModalGallery from "@/components/ModalGallery.vue"
import 'vue-material-design-icons/styles.css'
// import Ribbon from 'vue-ribbon'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
// import Flipbook from './Flipbook'
import Flipbook from 'flipbook-vue'

export default {
	name: "Profile",
	components: {
		// PageBanner,
		Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon
	},
	data() {
		return {
			pageTitle: "",
			pageDsrcptn: "",
			pageImg: "slider_archive.jpg",
			profileImages: [],
			index: null,
			showModal: false,
			indexArray: [],
			singleItem: {
				id: this.$route.params.id
			},
			// pagesLista: ['https://picsum.photos/id/237/800/1000', 'https://picsum.photos/seed/picsum/800/1000', 'https://picsum.photos/id/247/800/1000', 'https://picsum.photos/id/237/800/1000', 'https://picsum.photos/id/3237/800/1000', 'https://picsum.photos/id/337/800/1000', 'https://picsum.photos/id/237/800/1000', 'https://picsum.photos/id/2937/800/1000'],
			pagesList: null,
			pages: [],
			pagesHiRes: [],
			hasMouse: true,
			pageNum: null
		}
	},
	computed: {
		...Vuex.mapState(['profileData', 'loading']),
		...Vuex.mapGetters(['loadingStatus', 'getResultIds']),
		currentRecordIndex() {
			const recs = this.$store.getters.getResultIds
			return recs.indexOf(this.$route.params.id)
		},
		resultsLength() {
			return this.$store.getters.getResultIds.length
		}
	},
	methods: {
		async loadCurrentProfile(id) {
			this.$store.dispatch('loadProfile', id)
			// this.loadImages(id)
			this.loadFlipBookImages()
		},
		loadFlipBookImages() {
			this.pagesList = this.profileData.pdfImages
		},
		goBack() {
			this.$router.go(-1)
		}
	},
	mounted() {
		// console.log(this.indexArray)
	},
	updated() {
		// console.log(this.indexArray)
		this.loadFlipBookImages()
	},
	created() {
		this.isLoading = true
		this.indexArray = this.$store.getters.getResultIds
		this.pageNum = this.$route.params.pageNumber
		this.loadCurrentProfile(this.$route.params.id)
	}
}
</script>
<style lang="scss" scoped>
.flipbook {
  width: 90vw;
  height: 90vh;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: #ccc;
  overflow: hidden;
}
a {
  color: inherit;
}
.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar .btn {
  font-size: 30px;
  color: #999;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 28px;
}
.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 50px - 40px);
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}
.credit {
  font-size: 12px;
  line-height: 20px;
  margin: 10px;
}

.img-container {
	text-align:left;
}
.description-container {
	text-align:left;
	margin-top:5%;
}
.description-container > .inner {
	overflow:auto;
	min-height:200px;
}
.archive-description {
	white-space: pre-wrap;
    overflow-y: auto;
    max-height: 28vw;
    overflow-x: hidden;
    text-align: justify;
}
.pdf-title{
	margin-left:3%;
}
.single-archive-container {
	height: 100%;
	min-height: fit-content;
	min-height: -moz-fit-content;
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: -moz-fit-content;
	min-height: fit-content;
	min-height: 45vh;
}

.row {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 0 15% 5% 15%;
	margin: 0;

		.src-btn {
		background-color: $tur-blue;
		border: none;
		-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		width: 16%;
		min-width: 170px;
		white-space: nowrap;
		margin-left: 2%;
		text-align: center;

		img,span {
			margin-right: 20px;
		}
	}

	.src-btn:active,
	.src-btn:focus {
		box-shadow: none!important;
		background-color: $tur-blue!important;
		border: $light-green;
	}

	h4 {
		font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
	}

	img {
		width: 20px;
		// margin: -10px 0 0 5px
	}
}
	.about-container {
		padding-top: 5%;
	}
	.about-lead {
		width: 100%;
		display: flex;
		min-height: 20vh;
	}

		.main-logo-container {
		margin: 0% auto 4% auto;
		width: 100%;
		text-align: left;

			img {
				width: 20%;
				min-width: 150px;
			}
		}

	.about-lead-photo {
		width: 50%;
		.inner {
			//background-image: url('../assets/images/photo_foundation.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 100%;
		}
	}

	.fr-container {
		width: 100%;
    text-align: right;

		.trigger-modal {
			background-color: #95b136;
			border: none;
			color: #fff;
			border-radius: 5px;
			-webkit-appearance: none;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		}
	}

	.about-lead-text {
		width: 50%;
		padding: 0 5%;
		max-height: 128vh;
		overflow: hidden;
		position: relative;

		.lead-title {
			max-width: 635px;
		}

		h2 {
			font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
			font-weight: 100;
			color: #000;
			text-align: left;
			// margin-top: -2%;
			//background-image: url('../assets/images/logo_short.png');
			background-position: 75% 50%;
			background-size: contain ;
			background-repeat: no-repeat;

			.dash {
				width: 25%;
				margin: 5% 0;
			}
		}

		p {
			font-size: 20px;
			font-weight: 100;
			color: #000;
			text-align: justify;
			width: 80%;
			max-width: 500px;
			margin-bottom: 0;
		}
	}
	.about-main-content {
		padding: 5% 25%;

		p {
			text-align: justify;
			font-size: 1rem;
			line-height: 1.8;
		}
	}

		.l-logo {
			display: none;
		}
	@media(min-width: 996px) {

		.l-logo-cont {
			display: none;
		}
	}
	@media(max-width: 1200px) {

		.about-lead-photo {

			.inner {
				//background-image: url('../assets/images/laskaridi.jpg');
				background-size: cover;
				background-position-x: left;
			}
		}

		.about-main-content {
			padding: 5% 15%;
		}

	}

	@media (max-width: 996px) {
		.about-lead {
			flex-direction: column-reverse;
			flex-wrap: wrap;

			.main-logo-container {
				text-align: center;
			}

			.about-lead-photo {
				width: 90%;
				height: 33vw;
				margin-top: 5%;

				.inner {
				//	background-image: url('../assets/images/photo_foundation.jpg');
				}
			}

			.about-lead-text {
				width: 100%;
				padding: 0 10%;

				.lead-title {
					max-width: 100vw;
				}

				h2 {
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					background: #fff;
					.dash {
						margin: 2% 0;
						width: 15%;
					}
				}

				p {
					width: 100%;
					max-width: 100%;
					font-size: 1rem;
				}
			}
		}

			.l-logo-cont {
				padding: 5% 0;
				width: 100%;
				text-align: center;

				img {
					max-width: 12rem;
				}
			}

		.about-main-content {
			padding: 5% 10%;

			p {
			font-size: 14px;
			}

			.l-logo {
				text-align: center;
				width: 100%;
				img{
					width: 14%;
					min-width: 80px;
				}
			}
		}
	}

	@media(max-width: 670px) {

		.about-lead-text h2 {
			font-size: 40px;
			// margin-top: 3%;
			margin-bottom: 4%;
		}
	}
</style>
