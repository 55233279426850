<template>
	<div>
		<Loader v-if="isLoading" />
		<generic-page
			ref="gpage"
			:main-banner="mainImg"
			:p-content="pageContent"
			:p-title="pageTitle"
		></generic-page>
		<div
			class="filler centering"
			v-if="!pageContent"
		><h2>Sorry, no content</h2></div>
	</div>
</template>

<script>

export default {
	name: "ArchDescriptions",
	components: {
	},
	data() {
		return {
			pageContent: "",
			pageTitle: "",
			mainImg: "slider_archive.jpg",
			isLoading: false
		}
	},
	created() {
		this.isLoading = true
		this.getContent()
	},
	methods: {
		getContent() {
			this.$http
				.get(`${this.$store.state.rootUrl}site_pages/${this.$route.params.descripition}`)
				.then((response) => {
					this.isLoading = false
					this.pageContent = response.data[`o:block`][1][`o:data`].html
					this.pageTitle = response.data[`o:title`]
				})
		},
		getFrench(slug) {
			if (slug) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.$refs.gpage.frTitle = res.data.page.fr_title
						this.$refs.gpage.frContent = res.data.page.fr_content
					})
			}
		}
	},
	mounted() {
		this.getFrench(this.$route.params.descripition)
	}
}
</script>

<style lang="scss" scoped>
	.filler {
		height: 65vh;
	}
</style>
